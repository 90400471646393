import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { useEffect, useRef } from "react";

export default function CourierMap({ deliveries = [], activeDeliveryId }) {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && deliveries.length) {
      const bounds = deliveries.map((d) => [d.lat, d.lng]);
      mapRef.current.setBounds(bounds, {
        checkZoomRange: true,
        zoomMargin: 30,
      });
    }
  }, [deliveries]);

  useEffect(() => {
    if (!mapRef.current || !activeDeliveryId) return;

    const delivery = deliveries.find((d) => d.id === activeDeliveryId);
    if (delivery) {
      mapRef.current.panTo([delivery.lat, delivery.lng], {
        flying: true,
        duration: 1500,
      });
    }
  }, [activeDeliveryId, deliveries]);

  return (
    <YMaps>
      <Map
        defaultState={{
          center: [51.1605, 71.4704],
          zoom: 10,
        }}
        width="100%"
        height="100%"
        instanceRef={(ref) => (mapRef.current = ref)}
      >
        {deliveries.map((d) => (
          <Placemark
            key={d.id}
            geometry={[d.lat, d.lng]}
            options={{
              preset:
                d.id === activeDeliveryId
                  ? "islands#redDotIcon"
                  : "islands#blueDotIcon",
              openBalloonOnClick: false, // отключаем
            }}
          />
        ))}
      </Map>
    </YMaps>
  );
}