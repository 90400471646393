import { useState } from "react";
import styles from "./CourierPage.module.css";
import ChatButton from "../../components/ChatButton/ChatButton";
import CourierMap from "./CourierMap";

const mockDeliveries = [
    {
        id: 1,
        customerName: "Айдана",
        customerPhone: "+7 777 123 45 67",
        address: "ул. Сатпаева, д. 42, Алматы",
        lat: 43.236392,
        lng: 76.945854,
        productName: "Смартфон X",
        quantity: 1,
        code: "753214",
        courierEarnings: 1200,
        seller: {
            type: "individual",
            name: "soundwave",
            phone: "+7 707 777 77 77"
        },
        assignedAt: "2024-04-02T14:30:00"
    },
    {
        id: 2,
        customerName: "Игорь",
        customerPhone: "+7 701 999 88 77",
        address: "пр. Абая, д. 17, Астана",
        lat: 51.169392,
        lng: 71.449074,
        productName: "Наушники Pro",
        quantity: 2,
        code: "941007",
        courierEarnings: 800,
        seller: {
            type: "legal",
            name: "techworld",
            phone: "+7 727 222 22 22"
        },
        assignedAt: "2024-04-02T14:45:00"
    },
    {
        id: 3,
        customerName: "Жанна",
        customerPhone: "+7 702 444 55 66",
        address: "ул. Назарбаева, д. 88, Шымкент",
        lat: 42.341476,
        lng: 69.590082,
        productName: "Смарт-часы Ultra",
        quantity: 1,
        code: "853199",
        courierEarnings: 1500,
        seller: {
            type: "individual",
            name: "ultra_shop",
            phone: "+7 705 888 88 88"
        },
        assignedAt: "2024-04-02T15:10:00"
    }
];

export default function CourierPage() {
    const [token, setToken] = useState("");
    const [deliveries, setDeliveries] = useState(
        mockDeliveries.map((d) => ({
            ...d,
            enteredCode: "",
            isConfirmed: false
        }))
    );
    const [selectedDeliveryId, setSelectedDeliveryId] = useState(null);


    const handleActivate = () => {
        if (token.trim()) {
            const mockNewDelivery = {
                id: Date.now(),
                customerName: "Марина",
                address: "пр. Назарбаева, д. 123, Астана",
                productName: "Фитнес-браслет Ultra 7",
                code: "832199",
                enteredCode: "",
                isConfirmed: false
            };

            setDeliveries((prev) => [...prev, mockNewDelivery]);
            setToken("");
        }
    };

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.page}>
                <h2 className={styles.title}>📦 Страница курьера</h2>

                <div className={styles.tokenSection}>
                    <label htmlFor="token">Вставить токен от отправителя для идентификации адреса доставки:</label>
                    <input
                        id="token"
                        type="text"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        placeholder="Например: f23c9a7e-0b4c-49a7-b5d7-7af6d3a59e29"
                    />
                    <button onClick={handleActivate}>Активировать</button>
                </div>

                {deliveries.map((delivery, i) => (
                    <div
                        key={delivery.id}
                        className={styles.deliveryCard}
                        onClick={() => setSelectedDeliveryId(delivery.id)} // 🟢 Вот тут!
                    >
                        <div className={styles.deliveryCustomer}>
                            <p><strong>Получатель:</strong> {delivery.customerName}</p>
                            <p><strong>Телефон:</strong> {delivery.customerPhone}</p>
                            <p><strong>Адрес доставки:</strong> {delivery.address}</p>
                            <p><strong>Товар:</strong> {delivery.productName}</p>
                            <p><strong>Количество:</strong> {delivery.quantity}</p>
                            <p><strong>Доход курьера:</strong> {delivery.courierEarnings} ₸</p>
                        </div>

                        <div className={styles.deliverySeller}>
                            <p><strong>Продавец:</strong> {delivery.seller.type === "legal" ? "🏢 Юридическое лицо" : "🧑 Физическое лицо"} — {delivery.seller.name}</p>
                            <p><strong>Номер продавца:</strong> {delivery.seller.phone}</p>
                            <p><strong>Дата назначения:</strong> {new Date(delivery.assignedAt).toLocaleString("ru-RU")}</p>
                        </div>

                        <ChatButton userPhone={delivery.customerPhone} />


                        {delivery.isConfirmed ? (
                            <p className={styles.confirmed}>✅ Товар вручён. Подтверждение получено.</p>
                        ) : (
                            <>
                                <p><strong>Вставить код от получателя в момент передачи посылки для получения средств на счет курьера:</strong></p>
                                <input
                                    type="text"
                                    value={delivery.enteredCode}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setDeliveries((prev) =>
                                            prev.map((d, idx) =>
                                                idx === i ? { ...d, enteredCode: value } : d
                                            )
                                        );
                                    }}
                                    className={styles.codeInput}
                                />
                                <button
                                    onClick={() => {
                                        if (delivery.enteredCode === delivery.code) {
                                            setDeliveries((prev) =>
                                                prev.map((d, idx) =>
                                                    idx === i ? { ...d, isConfirmed: true } : d
                                                )
                                            );
                                        } else {
                                            alert("❌ Неверный код подтверждения");
                                        }
                                    }}
                                    className={styles.confirmButton}
                                >
                                    Подтвердить вручение
                                </button>
                            </>
                        )}
                    </div>
                ))}
            </div>

            <div className={styles.mapContainer}>
                <CourierMap deliveries={deliveries} activeDeliveryId={selectedDeliveryId} />
            </div>
        </div>
    );
}