import { useState } from "react";
import styles from "./AmbassadorList.module.css";
import placeholder from '../../assets/images/Ambassador/placeholder.jpg';
import { notify } from "../../components/NotificationContainer/NotificationContainer";

const AmbassadorList = ({ purchaseHistory = [] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isInviteOpen, setIsInviteOpen] = useState(false);

    const ambassadors = [
        { name: "Айдана Ермек" },
        { name: "Нурлан Касен" },
        { name: "Жанна Т." },
        { name: "Игорь Жуков" },
        { name: "Асель Нуриева" },
        { name: "Марат Досмухамбетов" },
        { name: "Дана Ахметова" },
        { name: "Сергей Иванов" },
        { name: "Лаура Шарипова" },
        { name: "Алишер Султанов" },
        { name: "Камилла Алиева" },
        { name: "Тимур Абдуллин" },
        { name: "Асем Кенжебаева" },
        { name: "Артём Павлов" },
        { name: "Гаухар Тулегенова" },
        { name: "Руслан Бекенов" },
        { name: "София Белова" },
        { name: "Ернар Саипов" },
        { name: "Кристина М." },
        { name: "Максим Ермолаев" }
    ];

    const getStats = (name) => {
        const relevant = purchaseHistory.filter(
            (p) => p.name === name || p.name.includes(name)
        );

        const count = relevant.length;

        const grouped = {};
        const discountsSet = new Set();

        for (const p of relevant) {
            const key = `${p.discount}%`;
            const earned = Math.round((p.amount * p.discount) / 100);
            grouped[key] = (grouped[key] || 0) + earned;
            discountsSet.add(p.discount);
        }

        const total = Object.values(grouped).reduce((sum, v) => sum + v, 0);
        const earningsBreakdown = Object.entries(grouped)
            .map(([discount, sum]) => `${discount} — ${sum} ₸`)
            .join(", ");

        const discountSummary = [...discountsSet]
            .sort((a, b) => a - b)
            .map(d => `${d}%`)
            .join(", ");

        return {
            count,
            total,
            earningsBreakdown,
            discountSummary
        };
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.buttonRow}>
                <button
                    onClick={() => {
                        setIsInviteOpen(false);
                        setIsOpen((prev) => !prev);
                    }}
                    className={styles.toggleBtn}
                >
                    {isOpen ? "Скрыть список амбассадоров" : "Список амбассадоров"}
                </button>

                <button
                    onClick={() => {
                        setIsOpen(false);
                        setIsInviteOpen((prev) => !prev);
                    }}
                    className={styles.inviteBtn}
                >
                    {isInviteOpen ? "Скрыть приглашение" : "Пригласить амбассадоров"}
                </button>
            </div>

            {isOpen && (
                <>
                    <div className={styles.list}>
                        {ambassadors.map((a, index) => {
                            const stats = getStats(a.name);
                            return (
                                <div key={index} className={styles.item}>
                                    <div className={styles.row}>
                                        <span className={styles.name}>
                                            <strong>{index + 1}.</strong>&nbsp;{a.name}
                                        </span>
                                        <span>Продаж: <strong>{stats.count}</strong></span>
                                        <span>Заработано: <strong>{stats.total} ₸</strong></span>
                                    </div>
                                    <div className={styles.subRow}>
                                        <span>Суммы: <strong>{stats.earningsBreakdown || "—"}</strong></span>
                                        <span>Скидки: <strong>{stats.discountSummary || "—"}</strong></span>
                                    </div>
                                </div>
                            );
                        })}

                        {/* ⬇️ Добавляем итого */}
                        <div className={styles.totalRow}>
                            Общая сумма по скидкам:&nbsp;
                            <strong>
                                {ambassadors.reduce((sum, a) => sum + getStats(a.name).total, 0)} ₸
                            </strong>
                        </div>
                    </div>
                </>
            )}

            {isInviteOpen && (
                <div className={styles.list}>
                    <div className={styles.inviteGrid}>
                        {[
                            { name: "Дарис", rating: 5 },
                            { name: "Сергей", rating: 3 },
                            { name: "Аманжол", rating: 4 },
                            { name: "Жансая", rating: 2 },
                            { name: "Сергей", rating: 1 },
                            { name: "Аманжол", rating: 5 },
                            
                            { name: "Жансая", rating: 0 }
                        ].map((amb, index) => (
                            <div key={index} className={styles.card}>
                                <img className={styles.avatar} src={placeholder} alt={amb.name} />
                                <div className={styles.name}>{amb.name}</div>
                                <div className={styles.stars}>
                                    {Array.from({ length: 5 }).map((_, i) => (
                                        <span key={i}>
                                            {i < amb.rating ? "★" : "☆"}
                                        </span>
                                    ))}
                                </div>
                                <button
                                    className={styles.cardButton}
                                    onClick={() => {
                                        notify(`Приглашение отправлено: ${amb.name}`);
                                        // здесь можешь вызвать API или записать в state
                                    }}
                                >
                                    Пригласить и заявить о своей компании
                                </button>
                            </div>
                        ))}

                    </div>
                </div>
            )}


        </div>
    );
};

export default AmbassadorList;