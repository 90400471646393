import { useState } from "react";
import styles from "./DiscountSection.module.css";

const DiscountSection = ({ onPurchase }) => {
    const [amount, setAmount] = useState(1000);
    const [discounts, setDiscounts] = useState([5, 7, 10]);
    const [selected, setSelected] = useState(5);

    const [showDraft, setShowDraft] = useState(false);
    const [products, setProducts] = useState([
        { name: "Товар 1", discount: 5 },
        { name: "Товар 2", discount: null },
    ]);
    const [isManual, setIsManual] = useState(false);

    const handleAdd = () => {
        const value = prompt("Введите новый процент:");
        const parsed = parseInt(value);
        if (!isNaN(parsed) && parsed > 0 && parsed <= 100) {
            setDiscounts([...discounts, parsed]);
        }
    };

    const discountAmount = Math.round((Number(amount || 0) * selected) / 100);

    const handlePurchase = () => {
        // для тестов выбираем случайного амбассадора
        const fakeAmbassadors = [
            "Айдана Ермек",
            "Нурлан Касен",
            "Жанна Т.",
            "Игорь Жуков",
            "Асель Нуриева",
            "Марат Досмухамбетов",
            "Дана Ахметова",
            "Сергей Иванов",
            "Лаура Шарипова",
            "Алишер Султанов",
            "Камилла Алиева",
            "Тимур Абдуллин",
            "Асем Кенжебаева",
            "Артём Павлов",
            "Гаухар Тулегенова",
            "Руслан Бекенов",
            "София Белова",
            "Ернар Саипов",
            "Кристина М.",
            "Максим Ермолаев"
        ];
        const fakeAmbassador = fakeAmbassadors[Math.floor(Math.random() * fakeAmbassadors.length)];

        const data = {
            name: fakeAmbassador,
            amount,
            discount: selected
        };
        onPurchase?.(data);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <button onClick={handleAdd} className={styles.tabAdd}>+</button>
                {discounts.map((d, i) => (
                    <button
                        key={i}
                        className={`${styles.tab} ${selected === d ? styles.active : ""}`}
                        onClick={() => setSelected(d)}
                    >
                        {d}%
                    </button>
                ))}
            </div>

            <h4>Выставить счёт </h4>
            <div className={styles.content}>
                <div className={styles.block}>
                    <label className={styles.label}>Сумма:</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => {
                            const val = e.target.value;
                            if (val === "") {
                                setAmount(""); // пустая строка — временно
                            } else {
                                setAmount(Number(val));
                            }
                        }}
                        className={styles.input}
                    />
                </div>
                <div className={styles.block}>
                    Скидка {selected}% = <strong>{discountAmount} ₸</strong>
                </div>
                <button className={styles.qrButton} onClick={handlePurchase}>
                    Выставить QR
                </button>
            </div>

            <div className={styles.switchWrapper}>
                <label className={styles.switchLabel}>
                    <h4>Установить скидки на товары</h4>
                    <span>{showDraft ? "Скрыть" : "Показать"}</span>
                    <input
                        type="checkbox"
                        checked={isManual}
                        onChange={() => setIsManual(prev => !prev)}
                        onClick={() => setShowDraft(prev => !prev)}
                        className={styles.switchInput}
                    />
                    <span className={styles.switchSlider}></span>
                </label>
            </div>

            {showDraft && (
                <div className={styles.draftSection}>
                    {products.length === 0 && (
                        <div className={styles.empty}>Список товаров пуст</div>
                    )}
                    {products.map((product, index) => {
                        const discount = product.discount || 0;
                        const price = Number(product.price || 0);
                        const finalPrice = Math.round(price - (price * discount) / 100);

                        return (
                            <div key={index} className={styles.productLine}>
                                <input
                                    type="text"
                                    value={product.name}
                                    onChange={(e) => {
                                        const updated = [...products];
                                        updated[index].name = e.target.value;
                                        setProducts(updated);
                                    }}
                                    className={styles.inlineInput}
                                    placeholder="Название"
                                />

                                <input
                                    type="number"
                                    value={product.price}
                                    onChange={(e) => {
                                        const updated = [...products];
                                        updated[index].price = Number(e.target.value);
                                        setProducts(updated);
                                    }}
                                    className={styles.inlineInput}
                                    placeholder="Сумма"
                                    min="0"
                                />

                                <select
                                    value={product.discount ?? ""}
                                    onChange={(e) => {
                                        const updated = [...products];
                                        const selectedValue = e.target.value === "" ? null : Number(e.target.value);
                                        updated[index].discount = selectedValue;
                                        setProducts(updated);
                                    }}
                                    className={styles.inlineSelect}
                                >
                                    <option value="">—</option>
                                    {discounts.map((d) => (
                                        <option key={d} value={d}>
                                            {d}%
                                        </option>
                                    ))}
                                </select>

                                <div className={styles.finalPrice}>{finalPrice} ₸</div>
                            </div>
                        );
                    })}
                    <button
                        onClick={() => {
                            const name = prompt("Введите название товара:");
                            if (name) {
                                setProducts([...products, { name, discount: null, price: 1000 }]);
                            }
                        }}
                        className={styles.addProduct}
                    >
                        Установить QR на все товары
                    </button>
                </div>
            )}
        </div>
    );
};

export default DiscountSection;