import React, { useState, useEffect } from "react";
import styles from "./PaymentModal.module.css";
import kaspiLogo from "../../assets/images/Payment/kaspi.png";
import halykLogo from "../../assets/images/Payment/halyk.png";
import freedomLogo from "../../assets/images/Payment/freedom.png";

const bankLogos = {
    kaspi: kaspiLogo,
    halyk: halykLogo,
    freedom: freedomLogo
};

const PaymentModal = ({ isOpen, onClose, onConfirm, banks = [], product }) => {
    const [selectedBank, setSelectedBank] = useState(null);
    const [paymentType, setPaymentType] = useState("full");
    const [allowInstallment, setAllowInstallment] = useState(true);
    const [qrGenerated, setQrGenerated] = useState(false);
    const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    useEffect(() => {
        const found = banks.find(b => b.name === selectedBank);
        setAllowInstallment(found?.installments ?? false);
        if (!found?.installments) setPaymentType("full");
    }, [selectedBank]);

    const handleConfirm = () => {
        if (!selectedBank) return alert("Выберите банк");

        setQrGenerated(true);
        setIsAwaitingConfirmation(true);

        setTimeout(() => {
            setIsPaid(true);
            setIsAwaitingConfirmation(false);
            onConfirm({ bank: selectedBank, type: paymentType });
        }, 5000); // имитация прихода оплаты
    };

    if (!isOpen) return null;

    const hasPromo = product.sellerType === "legal" && product.discount;
    const discountedPrice = hasPromo
        ? Math.round(product.price * (1 - product.discount / 100))
        : product.price;

    return (
        <div className={styles.overlay} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <button className={styles.modalCloseIcon} onClick={onClose} aria-label="Закрыть модальное окно">
                    ×
                </button>
                
                <div className={styles.content}>
                    <div className={styles.left}>
                        <h3>Оплата заказа</h3>

                        <div className={styles.productInfo}>
                            <div className={styles.imageBlock}>
                                <img src={product.image} alt={product.name} />
                            </div>

                            <div className={styles.productDetails}>
                                <div className={styles.productName}>{product.name}</div>
                                <div className={styles.priceLine}>
                                    {hasPromo ? (
                                        <>
                                            <span className={styles.oldPrice}>
                                                {product.price} {product.currency}
                                            </span>
                                            <span className={styles.newPrice}>
                                                {discountedPrice} {product.currency}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            {product.price} {product.currency}
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className={styles.sellerInfo}>
                                {product.sellerType === "legal" ? "🏢 Юр. лицо" : "🧑 Физ. лицо"} — {product.seller}
                                <div className={styles.location}>
                                    {product.city}, {product.country}
                                </div>

                                {/* 💸 Промокод от амбассадора */}
                                {product.sellerType === "legal" && product.discount && (
                                    <div className={styles.discountInfo}>
                                        🎁 Действует промокод на скидку всегда:
                                        <br />
                                        <strong>{product.discount}%</strong> от амбассадора
                                    </div>
                                )}

                                {/* Банки с рассрочкой */}
                                {product.paymentMethods?.some(p => p.installments) && (
                                    <div className={styles.installmentList}>
                                        <div className={styles.installmentLabel}>Рассрочка:</div>
                                        <div className={styles.installmentBanks}>
                                            {product.paymentMethods
                                                .filter(p => p.installments)
                                                .map(p => (
                                                    <span key={p.name} className={styles.installmentBadge}>{p.name}</span>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.section}>
                            <label>Выберите банк:</label>
                            <div className={styles.banks}>
                                {banks.map((b) => {
                                    const id = b.name.toLowerCase();
                                    return (
                                        <button
                                            key={b.name}
                                            className={`${styles.bankBtn} ${selectedBank === b.name ? styles.active : ""}`}
                                            onClick={() => setSelectedBank(b.name)}
                                        >
                                            {bankLogos[id] && (
                                                <img src={bankLogos[id]} alt={b.name} className={styles.bankLogo} />
                                            )}
                                            {b.name}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>

                        <div className={styles.sections}>
                            {/* 
                            <div className={styles.section}>
                                
                                <label>Тип оплаты:</label>
                                <div className={styles.payTypes}>
                                    <label className={styles.radioOption}>
                                        <input
                                            type="radio"
                                            value="full"
                                            checked={paymentType === "full"}
                                            onChange={() => setPaymentType("full")}
                                            className={styles.radioInput}
                                        />
                                        Полная оплата
                                    </label> 

                                {allowInstallment && (
                                    <label className={styles.radioOption}>
                                        <input
                                            type="radio"
                                            value="installment"
                                            checked={paymentType === "installment"}
                                            onChange={() => setPaymentType("installment")}
                                            className={styles.radioInput}
                                        />
                                        Рассрочка
                                    </label>
                                )}
                                 </div>
                            </div> */}
                        </div>

                        <div className={styles.actions}>
                            {!qrGenerated && (
                                <>
                                    <button onClick={handleConfirm} className={styles.confirmBtn}>Оплатить</button>
                                    <button onClick={onClose} className={styles.cancelBtn}>Отмена</button>
                                </>
                            )}

                            {isPaid && (
                                <button onClick={onClose} className={styles.closeAfterSuccessBtn}>
                                    Закрыть
                                </button>
                            )}
                        </div>
                    </div>

                    <div className={styles.right}>
                        Откройте приложение ad_SERVLET, перейдите в раздел "Юридические партнёры", вставьте в соответствующее поле промокод амбассадора и нажмите "Сканировать QR", чтобы получить скидку.
                        <p className={styles.qrLabel}>Сначала QR ad_SERVLET</p>

                        <div className={styles.qrBox}>
                            {qrGenerated ? (
                                <img
                                    src={`https://api.qrserver.com/v1/create-qr-code/?data=bank=${selectedBank},type=${paymentType}&size=150x150`}
                                    alt="QR"
                                />
                            ) : (
                                <img src="/qr-placeholder.png" alt="QR Placeholder" />
                            )}
                        </div>

                        {isAwaitingConfirmation && (
                            <p className={styles.qrStatus}>⏳ Ожидаем подтверждение платежа...</p>
                        )}

                        {isPaid && (
                            <p className={styles.successStatus}>✅ Платёж успешно подтверждён</p>
                        )}
                    </div>


                </div>
            </div>
        </div >
    );
};

export default PaymentModal;