const COUNTRIES_WITH_REGIONS = [
    {
        value: "KAZAKHSTAN",
        label: "Казахстан",
        regions: [
            { value: "ABAY", label: "Абайская область" },
            { value: "AKMOLA", label: "Акмолинская область" },
            { value: "AKTOBE", label: "Актюбинская область" },
            { value: "ALMATY_OBL", label: "Алматинская область" },
            { value: "ALMATY", label: "г. Алматы (город республиканского значения)" },
            { value: "ASTANA", label: "г. Астана (столица)" },
            { value: "ATYRAU", label: "Атырауская область" },
            { value: "EAST_KAZ", label: "Восточно-Казахстанская область" },
            { value: "JETISU", label: "Жетысуская область" },
            { value: "KARAGANDA", label: "Карагандинская область" },
            { value: "KOSTANAY", label: "Костанайская область" },
            { value: "KYZYLORDA", label: "Кызылординская область" },
            { value: "MANGYSTAU", label: "Мангистауская область" },
            { value: "NORTH_KAZ", label: "Северо-Казахстанская область" },
            { value: "SHYMKENT", label: "г. Шымкент (город республиканского значения)" },
            { value: "PAVLODAR", label: "Павлодарская область" },
            { value: "TURKESTAN", label: "Туркестанская область" },
            { value: "ULETAU", label: "Улытауская область" },
            { value: "WEST_KAZ", label: "Западно-Казахстанская область" }
        ]
    },
    {
        value: "RUSSIA",
        label: "Россия",
        regions: [
            { value: "MOSCOW", label: "г. Москва" },
            { value: "SPB", label: "г. Санкт-Петербург" },
            { value: "SEVASTOPOL", label: "г. Севастополь" },
            { value: "ADYGEA", label: "Республика Адыгея" },
            { value: "ALTAY", label: "Республика Алтай" },
            { value: "BASHKORTOSTAN", label: "Республика Башкортостан" },
            { value: "BURYATIA", label: "Республика Бурятия" },
            { value: "DAGESTAN", label: "Республика Дагестан" },
            { value: "INGUSHETIA", label: "Республика Ингушетия" },
            { value: "KABARDINO", label: "Кабардино-Балкарская Республика" },
            { value: "KALMYKIA", label: "Республика Калмыкия" },
            { value: "KARACHAEVO", label: "Карачаево-Черкесская Республика" },
            { value: "KARELIA", label: "Республика Карелия" },
            { value: "KOMI", label: "Республика Коми" },
            { value: "CRIMEA", label: "Республика Крым" },
            { value: "MARIEL", label: "Республика Марий Эл" },
            { value: "MORDOVIA", label: "Республика Мордовия" },
            { value: "SAKHA", label: "Республика Саха (Якутия)" },
            { value: "NORTH_OSSETIA", label: "Северная Осетия-Алания" },
            { value: "TATARSTAN", label: "Республика Татарстан" },
            { value: "TYVA", label: "Республика Тыва" },
            { value: "UDMURTIA", label: "Удмуртская Республика" },
            { value: "KHANTY", label: "Ханты-Мансийский АО" },
            { value: "CHUVASHIA", label: "Чувашская Республика" },
            { value: "ALTAY_KRAI", label: "Алтайский край" },
            { value: "KRASNODAR", label: "Краснодарский край" },
            { value: "KRASNOYARSK", label: "Красноярский край" },
            { value: "PERM", label: "Пермский край" },
            { value: "PRIMORSKY", label: "Приморский край" },
            { value: "STAVROPOL", label: "Ставропольский край" },
            { value: "KHABAROVSK", label: "Хабаровский край" },
            { value: "AMUR", label: "Амурская область" },
            { value: "ARKHANGELSK", label: "Архангельская область" },
            { value: "ASTRAKHAN", label: "Астраханская область" },
            { value: "BELGOROD", label: "Белгородская область" },
            { value: "BRYANSK", label: "Брянская область" },
            { value: "VLADIMIR", label: "Владимирская область" },
            { value: "VOLGOGRAD", label: "Волгоградская область" },
            { value: "VOLOGDA", label: "Вологодская область" },
            { value: "VORONEZH", label: "Воронежская область" },
            { value: "IVANOVO", label: "Ивановская область" },
            { value: "IRKUTSK", label: "Иркутская область" },
            { value: "KALININGRAD", label: "Калининградская область" },
            { value: "KALUGA", label: "Калужская область" },
            { value: "KEMEROVO", label: "Кемеровская область" },
            { value: "KIROV", label: "Кировская область" },
            { value: "KOSTROMA", label: "Костромская область" },
            { value: "KURGAN", label: "Курганская область" },
            { value: "KURSK", label: "Курская область" },
            { value: "LENINGRAD", label: "Ленинградская область" },
            { value: "LIPETSK", label: "Липецкая область" },
            { value: "MAGADAN", label: "Магаданская область" },
            { value: "MOSCOW_OBL", label: "Московская область" },
            { value: "MURMANSK", label: "Мурманская область" },
            { value: "NIZHNY_NOVGOROD", label: "Нижегородская область" },
            { value: "NOVGOROD", label: "Новгородская область" },
            { value: "NOVOSIBIRSK", label: "Новосибирская область" },
            { value: "OMSK", label: "Омская область" },
            { value: "OREL", label: "Орловская область" },
            { value: "ORENBURG", label: "Оренбургская область" },
            { value: "PENZA", label: "Пензенская область" },
            { value: "PSKOV", label: "Псковская область" },
            { value: "ROSTOV", label: "Ростовская область" },
            { value: "RYAZAN", label: "Рязанская область" },
            { value: "SAMARA", label: "Самарская область" },
            { value: "SARATOV", label: "Саратовская область" },
            { value: "SAKHALIN", label: "Сахалинская область" },
            { value: "SVERDLOVSK", label: "Свердловская область" },
            { value: "SMOLENSK", label: "Смоленская область" },
            { value: "TAMBOV", label: "Тамбовская область" },
            { value: "TOMSK", label: "Томская область" },
            { value: "TVER", label: "Тверская область" },
            { value: "TYUMEN", label: "Тюменская область" },
            { value: "ULYANOVSK", label: "Ульяновская область" },
            { value: "CHELYABINSK", label: "Челябинская область" },
            { value: "ZABAIKALSKY", label: "Забайкальский край" },
            { value: "YAROSLAVL", label: "Ярославская область" },
            { value: "YAMAL", label: "Ямало-Ненецкий АО" },
            { value: "JEWISH", label: "Еврейская автономная область" },
            { value: "CHUKOTKA", label: "Чукотский АО" }
        ]
    },
    {
        value: "BELARUS",
        label: "Беларусь",
        regions: [
            { value: "MINSK_CITY", label: "г. Минск" },
            { value: "BREST", label: "Брестская область" },
            { value: "VITEBSK", label: "Витебская область" },
            { value: "GOMEL", label: "Гомельская область" },
            { value: "GRODNO", label: "Гродненская область" },
            { value: "MINSK", label: "Минская область" },
            { value: "MOGILEV", label: "Могилёвская область" }
        ]
    },
    {
        value: "UKRAINE",
        label: "Украина",
        regions: [
            { value: "KYIV_CITY", label: "г. Киев" },
            { value: "VINNYTSIA", label: "Винницкая область" },
            { value: "VOLYN", label: "Волынская область" },
            { value: "DNIPROPETROVSK", label: "Днепропетровская область" },
            { value: "DONETSK", label: "Донецкая область" },
            { value: "ZHITOMIR", label: "Житомирская область" },
            { value: "ZAKARPATTIA", label: "Закарпатская область" },
            { value: "ZAPORIZHZHIA", label: "Запорожская область" },
            { value: "IVANO_FRANKIVSK", label: "Ивано-Франковская область" },
            { value: "KYIV", label: "Киевская область" },
            { value: "KIROVOHRAD", label: "Кировоградская область" },
            { value: "LUGANSK", label: "Луганская область" },
            { value: "LVIV", label: "Львовская область" },
            { value: "MYKOLAIV", label: "Николаевская область" },
            { value: "ODESA", label: "Одесская область" },
            { value: "POLTAVA", label: "Полтавская область" },
            { value: "RIVNE", label: "Ровенская область" },
            { value: "SUMY", label: "Сумская область" },
            { value: "TERNOPIL", label: "Тернопольская область" },
            { value: "KHMELNYTSKYI", label: "Хмельницкая область" },
            { value: "CHERKASY", label: "Черкасская область" },
            { value: "CHERNIVTSI", label: "Черновицкая область" },
            { value: "CHERNIHIV", label: "Черниговская область" }
        ]
    },
    {
        value: "AZERBAIJAN",
        label: "Азербайджан",
        regions: [
            { value: "BAKU", label: "г. Баку" },
            { value: "GANJA", label: "г. Гянджа" },
            { value: "SUMQAYIT", label: "г. Сумгайыт" },
            { value: "MINGACHEVIR", label: "г. Мингечевир" },
            { value: "LENKORAN", label: "г. Ленкорань" },
            { value: "SHIRVAN", label: "г. Ширван" },
            { value: "SHAKI", label: "г. Шеки" },
            { value: "YEVLAKH", label: "г. Евлах" },
            { value: "NAKHCHIVAN", label: "г. Нахичевань (НАР)" },
            { value: "KHACHMAZ", label: "г. Хачмаз" },

            // Районы Азербайджана:
            { value: "ABSERON", label: "Апшеронский район" },
            { value: "AGDAM", label: "Агдамский район" },
            { value: "AGDASH", label: "Агдашский район" },
            { value: "AGSTAF", label: "Агстафинский район" },
            { value: "AGSU", label: "Агсуйский район" },
            { value: "ASTARA", label: "Астаринский район" },
            { value: "BALAKEN", label: "Балакенский район" },
            { value: "BARDa", label: "Бардинский район" },
            { value: "BEYLAGAN", label: "Бейлаганский район" },
            { value: "BILASUVAR", label: "Билясуварский район" },
            { value: "DASHKESAN", label: "Дашкесанский район" },
            { value: "FIZULI", label: "Физулинский район" },
            { value: "GADABEY", label: "Гедабекский район" },
            { value: "GUBA", label: "Губинский район" },
            { value: "GUBADLI", label: "Губадлинский район" },
            { value: "GUSAR", label: "Гусарский район" },
            { value: "HAJIGABUL", label: "Гаджигабульский район" },
            { value: "IMISHLI", label: "Имишлинский район" },
            { value: "ISMAYILLI", label: "Исмаиллинский район" },
            { value: "JALILABAD", label: "Джалилабадский район" },
            { value: "JABRAYIL", label: "Джебраильский район" },
            { value: "KALBAJAR", label: "Кельбаджарский район" },
            { value: "KHACHMAZ", label: "Хачмазский район" },
            { value: "KHOJAVAND", label: "Ходжавендский район" },
            { value: "KHOJALY", label: "Ходжалинский район" },
            { value: "KURDAMIR", label: "Кюрдамирский район" },
            { value: "LACHIN", label: "Лачинский район" },
            { value: "LERIK", label: "Лерикский район" },
            { value: "MASALLY", label: "Масаллинский район" },
            { value: "NEFTCHALA", label: "Нефтечалинский район" },
            { value: "OGUZ", label: "Огузский район" },
            { value: "ORDUBAD", label: "Ордувадский район (НАР)" },
            { value: "QABALA", label: "Габалинский район" },
            { value: "QAX", label: "Кахский район" },
            { value: "QAZAX", label: "Газахский район" },
            { value: "QUBA", label: "Кубинский район" },
            { value: "QUSAR", label: "Кусарский район" },
            { value: "SABIRABAD", label: "Сабирабадский район" },
            { value: "SADARAK", label: "Садаракский район (НАР)" },
            { value: "SAHBUZ", label: "Шахбузский район (НАР)" },
            { value: "SAKHI", label: "Шекинский район" },
            { value: "SALIYAN", label: "Сальянский район" },
            { value: "SHAMAKHI", label: "Шемахинский район" },
            { value: "SHAMKIR", label: "Шамкирский район" },
            { value: "SHARUR", label: "Шарурский район (НАР)" },
            { value: "SHUSHA", label: "Шушинский район" },
            { value: "SIYAZAN", label: "Сиязанский район" },
            { value: "SUSA", label: "г. Шуша" },
            { value: "TARTAR", label: "Тертерский район" },
            { value: "TOVUZ", label: "Товузский район" },
            { value: "UJA", label: "Уджарский район" },
            { value: "YARDIMLI", label: "Ярдямлинский район" },
            { value: "YEVLAKH", label: "Евлахский район" },
            { value: "ZAKATALA", label: "Закатальский район" },
            { value: "ZARDAB", label: "Зардабский район" },
            { value: "ZANGILAN", label: "Зангеланский район" }
        ]
    },
    {
        value: "ARMENIA",
        label: "Армения",
        regions: [
            { value: "YEREVAN", label: "г. Ереван" },
            { value: "ARAGATSOTN", label: "Арагацотнская область" },
            { value: "ARARAT", label: "Араратская область" },
            { value: "ARMAVIR", label: "Армавирская область" },
            { value: "GEGHARKUNIK", label: "Гегаркуникская область" },
            { value: "KOTAYK", label: "Котайкская область" },
            { value: "LORI", label: "Лорийская область" },
            { value: "SHIRAK", label: "Ширакская область" },
            { value: "SYUNIK", label: "Сюникская область" },
            { value: "TAVUSH", label: "Тавушская область" },
            { value: "VAYOTS_DZOR", label: "Вайоц-Дзорская область" }
        ]
    },
    {
        value: "GEORGIA",
        label: "Грузия",
        regions: [
            { value: "TBILISI", label: "г. Тбилиси" },
            { value: "ADJARA", label: "Аджария (АССР)" },
            { value: "GURIA", label: "Гурия" },
            { value: "IMERETI", label: "Имерети" },
            { value: "KAKHETI", label: "Кахетия" },
            { value: "KVEMO_KARTLI", label: "Квемо-Картли" },
            { value: "MTSKHETA_MT", label: "Мцхета-Мтианети" },
            { value: "RACHA_LECHKUMI", label: "Рача-Лечхуми и Нижняя Сванетия" },
            { value: "SAMTSKHE_JAVAKHETI", label: "Самцхе-Джавахети" },
            { value: "SAMEGRELO", label: "Самегрело-Земо Сванети" },
            { value: "SHIDA_KARTLI", label: "Шида-Картли" }
        ]
    },
    {
        value: "KYRGYZSTAN",
        label: "Кыргызстан",
        regions: [
            { value: "BISHKEK", label: "г. Бишкек" },
            { value: "OSH_CITY", label: "г. Ош" },
            { value: "BATKEN", label: "Баткенская область" },
            { value: "CHUI", label: "Чуйская область" },
            { value: "JALAL_ABAD", label: "Джалал-Абадская область" },
            { value: "NARYN", label: "Нарынская область" },
            { value: "OSH", label: "Ошская область" },
            { value: "TALAS", label: "Таласская область" },
            { value: "ISSYK_KUL", label: "Иссык-Кульская область" }
        ]
    },
    {
        value: "MOLDOVA",
        label: "Молдова",
        regions: [
            { value: "CHISINAU", label: "г. Кишинёв" },
            { value: "BALTI", label: "г. Бельцы" },
            { value: "BENDER", label: "г. Бендеры (Тигина)" },
            // Автономное территориальное образование
            { value: "GAGAUZIA", label: "Гагаузия (АТО)" },
            // Районы Молдовы (по закону № 764 от 27.12.2001):
            { value: "ANENII_NOI", label: "Анений-Ной" },
            { value: "BASARABEASCA", label: "Бессарабка" },
            { value: "BRICENI", label: "Бричень" },
            { value: "CAHUL", label: "Кагул" },
            { value: "CANTEMIR", label: "Кантемир" },
            { value: "CAUSENI", label: "Каушаны" },
            { value: "CIMISLIA", label: "Чимишлия" },
            { value: "CRICOVA", label: "Крикова" },
            { value: "CRICOVA", label: "Крикова" },
            { value: "CRICOVA", label: "Крикова" },
            { value: "CRIULENI", label: "Криулень" },
            { value: "DONDUSENI", label: "Дондюшень" },
            { value: "DROCHIA", label: "Дрокия" },
            { value: "DUBASARI", label: "Дубэсарь" },
            { value: "EDINET", label: "Единец" },
            { value: "FALENI", label: "Фалешты" },
            { value: "FLORESTI", label: "Флорешты" },
            { value: "GLODENI", label: "Глодяны" },
            { value: "HINCESTI", label: "Хынчешты" },
            { value: "IALOVENI", label: "Яловены" },
            { value: "LEOVA", label: "Леова" },
            { value: "NISPORENI", label: "Ниспорены" },
            { value: "OCNITA", label: "Окница" },
            { value: "ORHEI", label: "Орхей" },
            { value: "REZINA", label: "Резина" },
            { value: "RISCANI", label: "Рышканы" },
            { value: "SANGEREI", label: "Сынжерей" },
            { value: "SOROCA", label: "Сорока" },
            { value: "STRAŞENI", label: "Страшены" },
            { value: "STEFAN_VODA", label: "Штефан-Водэ" },
            { value: "SOLDANESTI", label: "Шолданешты" },
            { value: "TARACLIA", label: "Тараклия" },
            { value: "TELESTI", label: "Телешты" },
            { value: "UNGHENI", label: "Унгены" },
            // Приднестровский регион (фактически контролируется ПМР)
            { value: "TIRASPOL", label: "г. Тирасполь (ПМР)" },
            { value: "DUBASARI_PM", label: "Дубэсарь (ПМР)" },
            { value: "RIBNITA", label: "Рыбница (ПМР)" },
            { value: "CAMIL", label: "Каменка (ПМР)" },
            { value: "GRIGORIOPOL", label: "Григориополь (ПМР)" },
            { value: "SLBOZIA", label: "Слободзея (ПМР)" }
        ]
    },
    {
        value: "TAJIKISTAN",
        label: "Таджикистан",
        regions: [
            // Города республиканского подчинения
            { value: "DUSHANBE", label: "г. Душанбе" },
            { value: "BOKHTAR_CITY", label: "г. Бохтар" },
            { value: "KHUJAND_CITY", label: "г. Худжанд" },
            { value: "KULOB_CITY", label: "г. Куляб" },
            { value: "ISFARA", label: "г. Исфара" },
            { value: "ISTARAVSHAN", label: "г. Истаравшан" },
            { value: "PANJAKENT", label: "г. Пенджикент" },
            // Области и области под национальной юрисдикцией
            { value: "SUGHD", label: "Согдийская область" },
            { value: "KHATLON", label: "Хатлонская область" },
            { value: "GBAO", label: "Горно-Бадахшанская автономная область (ГБАО)" },
            { value: "RRP", label: "Районы республиканского подчинения" }
        ]
    },
    {
        value: "TURKMENISTAN",
        label: "Туркменистан",
        regions: [
            // Города с правами велаятов
            { value: "ASHGABAT", label: "г. Ашхабад" },
            // Велаятлы (области)
            { value: "AHAL", label: "Ахалский велаят" },
            { value: "BALKAN", label: "Балканский велаят" },
            { value: "DASHOGUZ", label: "Дашогузский велаят" },
            { value: "LEBAP", label: "Лебапский велаят" },
            { value: "MARY", label: "Марыйский велаят" }
        ]
    },
    {
        value: "UZBEKISTAN",
        label: "Узбекистан",
        regions: [
            // Город с республиканским статусом
            { value: "TASHKENT_CITY", label: "г. Ташкент" },
            // Области (вилоятлар) и автономная республика
            { value: "ANDIJAN", label: "Андижанская область" },
            { value: "BUKHARA", label: "Бухарская область" },
            { value: "FERGHANA", label: "Ферганская область" },
            { value: "JIZZAKH", label: "Джизакская область" },
            { value: "KHOREZM", label: "Хорезмская область" },
            { value: "NAMANGAN", label: "Наманганская область" },
            { value: "NAVOI", label: "Навоийская область" },
            { value: "KASHKADARYA", label: "Кашкадарьинская область" },
            { value: "SAMARKAND", label: "Самаркандская область" },
            { value: "SURKHANDARYA", label: "Сурхандарьинская область" },
            { value: "SYRDARYA", label: "Сырдарьинская область" },
            { value: "TASHKENT", label: "Ташкентская область" },
            { value: "KARAKALPAKSTAN", label: "Республика Каракалпакстан (автономия)" }
        ]
    },
    // Европейский Союз (ЕС)
    {
        value: "GERMANY",
        label: "Германия",
        regions: [
            { value: "BW", label: "Баден-Вюртемберг" },
            { value: "BY", label: "Бавария" },
            { value: "BE", label: "Берлин" },
            { value: "BB", label: "Бранденбург" },
            { value: "HB", label: "Бремен" },
            { value: "HH", label: "Гамбург" },
            { value: "HE", label: "Гессен" },
            { value: "MV", label: "Мекленбург-Передняя Померания" },
            { value: "NI", label: "Нижняя Саксония" },
            { value: "NW", label: "Северный Рейн-Вестфалия" },
            { value: "RP", label: "Рейнланд-Пфальц" },
            { value: "SL", label: "Саар" },
            { value: "SN", label: "Саксония" },
            { value: "ST", label: "Саксония-Анхальт" },
            { value: "SH", label: "Шлезвиг-Гольштейн" },
            { value: "TH", label: "Тюрингия" }
        ]
    },
    {
        value: "FRANCE",
        label: "Франция",
        regions: [
            // Метрополия (континентальная Франция)
            { value: "AUVERGNE_RHONE_ALPES", label: "Овернь — Рона — Альпы" },
            { value: "BOURGOGNE_FRANCHE_COMTE", label: "Бургундия — Франш-Конте" },
            { value: "BRETAGNE", label: "Бретань" },
            { value: "CENTRE_VAL_DE_LOIRE", label: "Центр — Долина Луары" },
            { value: "CORSE", label: "Корсика" },
            { value: "GRAND_EST", label: "Гранд-Эст" },
            { value: "HAUTS_DE_FRANCE", label: "О-де-Франс" },
            { value: "ILE_DE_FRANCE", label: "Иль-де-Франс" },
            { value: "NORMANDIE", label: "Нормандия" },
            { value: "NOUVELLE_AQUITAINE", label: "Новая Аквитания" },
            { value: "OCCITANIE", label: "Окситания" },
            { value: "PAYS_DE_LA_LOIRE", label: "Пеи-де-ла-Луар" },
            { value: "PROVENCE_ALPES_COTE_DAZUR", label: "Прованс — Альпы — Лазурный Берег" },

            // Заморские регионы (регион = департамент)
            { value: "GUADELOUPE", label: "Гваделупа (заморский регион)" },
            { value: "GUYANE", label: "Гвиана (заморский регион)" },
            { value: "MARTINIQUE", label: "Мартиника (заморский регион)" },
            { value: "MAYOTTE", label: "Майотта (заморский регион)" },
            { value: "REUNION", label: "Реюньон (заморский регион)" }
        ]
    },
    {
        value: "ITALY",
        label: "Италия",
        regions: [
            { value: "ABRUZZO", label: "Абруццо" },
            { value: "BASILICATA", label: "Базиликата" },
            { value: "CALABRIA", label: "Калабрия" },
            { value: "CAMPANIA", label: "Кампания" },
            { value: "EMILIA_ROMAGNA", label: "Эмилия-Романья" },
            { value: "FRIULI_VENEZIA_GIULIA", label: "Фриули — Венеция-Джулия" },
            { value: "LAZIO", label: "Лацио" },
            { value: "LIGURIA", label: "Лигурия" },
            { value: "LOMBARDY", label: "Ломбардия" },
            { value: "MARCHE", label: "Марке" },
            { value: "MOLISE", label: "Молизе" },
            { value: "PIEDMONT", label: "Пьемонт" },
            { value: "APULIA", label: "Апулия (Пулья)" },
            { value: "SARDINIA", label: "Сардиния" },
            { value: "SICILY", label: "Сицилия" },
            { value: "TUSCANY", label: "Тоскана" },
            { value: "TRENTINO_SOUTH_TYROL", label: "Трентино — Альто-Адидже (Южный Тироль)" },
            { value: "UMBRIA", label: "Умбрия" },
            { value: "AOSTA_VALLEY", label: "Валле-д’Аоста" },
            { value: "VENETO", label: "Венето" }
        ]
    },
    {
        value: "SPAIN",
        label: "Испания",
        regions: [
            // Автономные сообщества Испании (17)
            { value: "ANDALUSIA", label: "Андалусия" },
            { value: "ARAGON", label: "Арагон" },
            { value: "ASTURIAS", label: "Астурия (Княжество Астурия)" },
            { value: "BALEARIC_ISLANDS", label: "Балеарские острова" },
            { value: "BASQUE_COUNTRY", label: "Страна Басков (Эускади)" },
            { value: "CANARY_ISLANDS", label: "Канарские острова" },
            { value: "CANTABRIA", label: "Кантабрия" },
            { value: "CASTILE_AND_LEON", label: "Кастилия и Леон" },
            { value: "CASTILE_LA_MANCHA", label: "Кастилия — Ла-Манча" },
            { value: "CATALONIA", label: "Каталония" },
            { value: "EXTREMADURA", label: "Эстремадура" },
            { value: "GALICIA", label: "Галисия" },
            { value: "LA_RIOJA", label: "Риоха" },
            { value: "MADRID", label: "Мадрид (автономное сообщество)" },
            { value: "MURCIA", label: "Мурсия" },
            { value: "NAVARRE", label: "Наварра" },
            { value: "VALENCIA", label: "Валенсия (Валенсийское сообщество)" },
            // 2 автономных города (ceuta и melilla)
            { value: "CEUTA", label: "Сеута (автономный город)" },
            { value: "MELILLA", label: "Мелилья (автономный город)" }
        ]
    },
    {
        value: "POLAND",
        label: "Польша",
        regions: [
            { value: "DOLNOSLASKIE", label: "Нижнесилезское воеводство (Dolnośląskie)" },
            { value: "KUJAWSKO_POMORSKIE", label: "Куявско-Поморское воеводство (Kujawsko-Pomorskie)" },
            { value: "LUBELSKIE", label: "Люблинское воеводство (Lubelskie)" },
            { value: "LUBUSKIE", label: "Любушское воеводство (Lubuskie)" },
            { value: "LODZKIE", label: "Лодзинское воеводство (Łódzkie)" },
            { value: "MALOPOLSKIE", label: "Малопольское воеводство (Małopolskie)" },
            { value: "MAZOWIECKIE", label: "Мазовецкое воеводство (Mazowieckie)" },
            { value: "OPOLSKIE", label: "Опольское воеводство (Opolskie)" },
            { value: "PODKARPACKIE", label: "Подкарпатское воеводство (Podkarpackie)" },
            { value: "PODLASKIE", label: "Подляское воеводство (Podlaskie)" },
            { value: "POMORSKIE", label: "Поморское воеводство (Pomorskie)" },
            { value: "SLASKIE", label: "Силезское воеводство (Śląskie)" },
            { value: "SWIETOKRZYSKIE", label: "Свентокшиское воеводство (Świętokrzyskie)" },
            { value: "WARMINSKO_MAZURSKIE", label: "Варминьско-Мазурское воеводство (Warmińsko-Mazurskie)" },
            { value: "WIELKOPOLSKIE", label: "Великопольское воеводство (Wielkopolskie)" },
            { value: "ZACHODNIOPOMORSKIE", label: "Западно-Поморское воеводство (Zachodniopomorskie)" }
        ]
    },
    {
        value: "NETHERLANDS",
        label: "Нидерланды",
        regions: [
            { value: "DRENTHE", label: "Дренте" },
            { value: "FLEVOLAND", label: "Флеволанд" },
            { value: "FRIESLAND", label: "Фрисландия" },
            { value: "GELDERLAND", label: "Гелдерланд" },
            { value: "GRONINGEN", label: "Гронинген" },
            { value: "LIMBURG", label: "Лимбург" },
            { value: "NORTH_BRABANT", label: "Северный Брабант" },
            { value: "NORTH_HOLLAND", label: "Северная Голландия" },
            { value: "OVERIJSSEL", label: "Оверэйсел" },
            { value: "SOUTH_HOLLAND", label: "Южная Голландия" },
            { value: "UTRECHT", label: "Утрехт" },
            { value: "ZEELAND", label: "Зеландия" }
        ]
    },
    {
        value: "SWEDEN",
        label: "Швеция",
        regions: [
            { value: "BLEKINGE", label: "Блекенге" },
            { value: "DALARNA", label: "Даларна" },
            { value: "GAVLEBORG", label: "Йевлеборг" },
            { value: "GOTLAND", label: "Готланд" },
            { value: "HALLAND", label: "Халланд" },
            { value: "JAMTLAND", label: "Емтланд" },
            { value: "JONKOPING", label: "Йёнчёпинг" },
            { value: "KALMAR", label: "Кальмар" },
            { value: "KRONOBERG", label: "Крунуберг" },
            { value: "NOREBOTTEN", label: "Норботтен" },
            { value: "OSTERGOTLAND", label: "Эстергётланд" },
            { value: "OREBRO", label: "Эребру" },
            { value: "SKANE", label: "Сконе" },
            { value: "SODERMANLAND", label: "Сёдерманланд" },
            { value: "STOCKHOLM", label: "Стокгольм" },
            { value: "UPPSALA", label: "Уппсала" },
            { value: "VARMLAND", label: "Вермланд" },
            { value: "VASTERBOTTEN", label: "Вестерботтен" },
            { value: "VASTERNORRLAND", label: "Вестерноррланд" },
            { value: "VASTMANLAND", label: "Вестманланд" },
            { value: "VASRAGOTALAND", label: "Вестра-Гёталанд" }
        ]
    },
    {
        value: "AUSTRIA",
        label: "Австрия",
        regions: [
            { value: "BURGENLAND", label: "Бургенланд" },
            { value: "CARINTHIA", label: "Каринтия (Кернтен)" },
            { value: "LOWER_AUSTRIA", label: "Нижняя Австрия" },
            { value: "UPPER_AUSTRIA", label: "Верхняя Австрия" },
            { value: "SALZBURG", label: "Зальцбург" },
            { value: "STYRIA", label: "Штирия" },
            { value: "TYROL", label: "Тироль" },
            { value: "VORARLBERG", label: "Форарльберг" },
            { value: "VIENNA", label: "Вена" }
        ]
    },
    {
        value: "BELGIUM",
        label: "Бельгия",
        regions: [
            // Три региона страны (уровень 1)
            { value: "FLANDERS", label: "Фламандский регион" },
            { value: "WALLONIA", label: "Валлонский регион" },
            { value: "BRUSSELS", label: "Брюссельский столичный регион" },
            // Провинции Фландрии (уровень 2)
            { value: "ANTWERP", label: "Антверпен" },
            { value: "EAST_FLANDERS", label: "Восточная Фландрия" },
            { value: "FLEMISH_BRABANT", label: "Фламандский Брабант" },
            { value: "LIMBURG", label: "Лимбург (Фландрия)" },
            { value: "WEST_FLANDERS", label: "Западная Фландрия" },
            // Провинции Валлонии (уровень 2)
            { value: "WALLON_BRABANT", label: "Валлонский Брабант" },
            { value: "NAMUR", label: "Намюр" },
            { value: "LIEGE", label: "Льеж" },
            { value: "LUXEMBOURG_BE", label: "Люксембург (Бельгия)" },
            { value: "HAINAUT", label: "Эно (Эно / Hainaut)" }
        ]
    },
    {
        value: "CZECHIA",
        label: "Чехия",
        regions: [
            { value: "PRAGUE", label: "Прага (Hlavní město Praha)" },
            { value: "CENTRAL_BOHEMIA", label: "Среднечешский край (Středočeský kraj)" },
            { value: "SOUTH_BOHEMIA", label: "Южночешский край (Jihočeský kraj)" },
            { value: "PLZEN", label: "Пльзенский край (Plzeňský kraj)" },
            { value: "KARLOVY_VARY", label: "Карловарский край (Karlovarský kraj)" },
            { value: "USTI_NAD_LABEM", label: "Устецкий край (Ústecký kraj)" },
            { value: "LIBEREC", label: "Либерецкий край (Liberecký kraj)" },
            { value: "HRADEC_KRALOVE", label: "Краловеградецкий край (Královéhradecký kraj)" },
            { value: "PARDUBICE", label: "Пардубицкий край (Pardubický kraj)" },
            { value: "VYSOCINA", label: "Край Высочина (Kraj Vysočina)" },
            { value: "SOUTH_MORAVIA", label: "Южноморавский край (Jihomoravský kraj)" },
            { value: "OLOMOUC", label: "Оломоуцкий край (Olomoucký kraj)" },
            { value: "ZLIN", label: "Злинский край (Zlínský kraj)" },
            { value: "MORAVIA_SILESIAN", label: "Моравскосилезский край (Moravskoslezský kraj)" }
        ]
    },
    {
        value: "PORTUGAL",
        label: "Португалия",
        regions: [
            // Континентальные регионы (NUTS II)
            { value: "NORTE", label: "Норте" },
            { value: "CENTRO", label: "Центр" },
            { value: "LISBON", label: "Лиссабон" },
            { value: "ALENTEJO", label: "Алентежу" },
            { value: "ALGARVE", label: "Алгарве" },
            // Автономные регионы
            { value: "AZORES", label: "Азорские острова (R. A. Açores)" },
            { value: "MADEIRA", label: "Мадейра (R. A. Madeira)" }
        ]
    },
    {
        value: "FINLAND",
        label: "Финляндия",
        regions: [
            { value: "UUSIMAA", label: "Уусимаа" },
            { value: "SOUTHWEST_FINLAND", label: "Юго-Западная Финляндия" },
            { value: "SATACKUNTA", label: "Сатакунта" },
            { value: "KANTA_HAME", label: "Канта-Хяме" },
            { value: "PIRKANMAA", label: "Пирканмаа" },
            { value: "PAIJAT_HAME", label: "Пяйят-Хяме" },
            { value: "KYMENLAAKSO", label: "Кюменлааксо" },
            { value: "SOUTH_KARELIA", label: "Южная Карелия" },
            { value: "SOUTH_SAVO", label: "Южное Саво" },
            { value: "NORTH_SAVO", label: "Северное Саво" },
            { value: "NORTH_KARELIA", label: "Северная Карелия" },
            { value: "CENTRAL_FINLAND", label: "Центральная Финляндия" },
            { value: "SOUTH_OSTROBOTHNIA", label: "Южная Остроботния" },
            { value: "OSTROBOTHNIA", label: "Остроботния" },
            { value: "CENTRAL_OSTROBOTHNIA", label: "Центральная Остроботния" },
            { value: "NORTH_OSTROBOTHNIA", label: "Северная Остроботния" },
            { value: "KAINUU", label: "Кайнуу" },
            { value: "LAPLAND", label: "Лапландия" },
            { value: "ALAND", label: "Аландские острова (автономия)" }
        ]
    },
    {
        value: "GREECE",
        label: "Греция",
        regions: [
            { value: "ATTICA", label: "Аттика" },
            { value: "CENTRAL_GREECE", label: "Центральная Греция" },
            { value: "CENTRAL_MACEDONIA", label: "Центральная Македония" },
            { value: "CRETE", label: "Крит" },
            { value: "EAST_MACEDONIA_THRACE", label: "Восточная Македония и Фракия" },
            { value: "EPIRUS", label: "Эпир" },
            { value: "IONIAN_ISLANDS", label: "Ионические острова" },
            { value: "NORTH_AEGEAN", label: "Северные Эгейские острова" },
            { value: "PELOPONNESE", label: "Пелопоннес" },
            { value: "SOUTH_AEGEAN", label: "Южные Эгейские острова" },
            { value: "THESSALY", label: "Фессалия" },
            { value: "WEST_GREECE", label: "Западная Греция" },
            { value: "WEST_MACEDONIA", label: "Западная Македония" },
            { value: "MOUNT_ATHOS", label: "Афон (автономная монашеская область)" }
        ]
    },
    {
        value: "DENMARK",
        label: "Дания",
        regions: [
            { value: "CAPITAL_REGION", label: "Столичный регион (Region Hovedstaden)" },
            { value: "CENTRAL_DENMARK", label: "Центральная Ютландия (Region Midtjylland)" },
            { value: "NORTH_DENMARK", label: "Северная Ютландия (Region Nordjylland)" },
            { value: "ZEALAND", label: "Зеландия (Region Sjælland)" },
            { value: "SOUTH_DENMARK", label: "Южная Дания (Region Syddanmark)" }
        ]
    },
    {
        value: "IRELAND",
        label: "Ирландия",
        regions: [
            // Провинции
            { value: "LEINSTER", label: "Ленстер" },
            { value: "MUNSTER", label: "Манстер" },
            { value: "CONNACHT", label: "Коннахт" },
            { value: "ULSTER_IE", label: "Ольстер (только часть в Республике Ирландия)" },

            // Региональные подразделения NUTS III (официальные регионы)
            { value: "DUBLIN", label: "Дублин" },
            { value: "MID_EAST", label: "Средний Восток (Mid-East)" },
            { value: "MIDLANDS", label: "Срединные земли (Midlands)" },
            { value: "MID_WEST", label: "Средний Запад (Mid-West)" },
            { value: "SOUTH_EAST", label: "Юго-Восток (South-East)" },
            { value: "SOUTH_WEST", label: "Юго-Запад (South-West)" },
            { value: "WEST", label: "Запад (West)" }
        ]
    },
    {
        value: "HUNGARY",
        label: "Венгрия",
        regions: [
            { value: "BUDAPEST", label: "Будапешт (столица)" },
            { value: "PEST", label: "Пешт" },
            { value: "BACS_KISKUN", label: "Бач-Кишкун" },
            { value: "BARANYA", label: "Бараня" },
            { value: "BEKES", label: "Бекеш" },
            { value: "BORSOD_ABAUJ_ZEMPLEN", label: "Боршод-Абауй-Земплен" },
            { value: "CSONGRAD_CSANAD", label: "Чонград-Чанад" },
            { value: "FEJER", label: "Фейер" },
            { value: "GYOR_MOSON_SOPRON", label: "Дьёр-Мошон-Шопрон" },
            { value: "HAJDÚ_BIHAR", label: "Хайду-Бихар" },
            { value: "HEVES", label: "Хевеш" },
            { value: "JASZ_NAGYKUN_SZOLNOK", label: "Яс-Надькун-Сольнок" },
            { value: "KOMAROM_ESZTERGOM", label: "Комаром-Эстергом" },
            { value: "NOGRAD", label: "Ноград" },
            { value: "SOMOGY", label: "Шомодь" },
            { value: "SZABOLCS_SZATMÁR_BEREG", label: "Сабольч-Сатмар-Берег" },
            { value: "TOLNA", label: "Тольна" },
            { value: "VAS", label: "Ваш" },
            { value: "VESZPREM", label: "Веспрем" },
            { value: "ZALA", label: "Зала" }
        ]
    },
    {
        value: "SLOVAKIA",
        label: "Словакия",
        regions: [
            { value: "BRATISLAVA", label: "Братиславский край (Bratislavský kraj)" },
            { value: "TRNAVA", label: "Трнавский край (Trnavský kraj)" },
            { value: "TRENČÍN", label: "Тренчинский край (Trenčiansky kraj)" },
            { value: "NITRA", label: "Нитранский край (Nitriansky kraj)" },
            { value: "ŽILINA", label: "Жилинский край (Žilinský kraj)" },
            { value: "BANSKÁ_BYSTRICA", label: "Банскобистрицкий край (Banskobystrický kraj)" },
            { value: "PREŠOV", label: "Прешовский край (Prešovský kraj)" },
            { value: "KOŠICE", label: "Кошицкий край (Košický kraj)" }
        ]
    },
    {
        value: "SLOVENIA",
        label: "Словения",
        regions: [
            { value: "POMURSKA", label: "Помурска" },
            { value: "PODRAVSKA", label: "Подравска" },
            { value: "KOROSKA", label: "Корошка" },
            { value: "SAVINJSKA", label: "Савиньска" },
            { value: "ZASAVSKA", label: "Засавска" },
            { value: "SPODNJEPOSAVSKA", label: "Нижнепосавска (Посавье)" },
            { value: "SOUTHEAST_SLOVENIA", label: "Юго-Восточная Словения (Jugovzhodna Slovenija)" },
            { value: "CENTRAL_SLOVENIA", label: "Центральная Словения (Osrednjeslovenska)" },
            { value: "UPPER_CARNIOLA", label: "Гореньска (Gorenjska)" },
            { value: "GORIZIA", label: "Горица (Goriška)" },
            { value: "COAST_KARST", label: "Приморско-Нотраньска (Область побережья и карста)" }
        ]
    },
    {
        value: "LATVIA",
        label: "Латвия",
        regions: [
            { value: "RIGA", label: "Рижский регион (Rīgas reģions)" },
            { value: "KURZEME", label: "Курземе" },
            { value: "VIDZEME", label: "Видземе" },
            { value: "ZEMGALE", label: "Земгале" },
            { value: "LATGALE", label: "Латгалия" }
        ]
    },
    {
        value: "LITHUANIA",
        label: "Литва",
        regions: [
            { value: "ALYTUS", label: "Алитусский уезд (Alytaus apskritis)" },
            { value: "KAUNAS", label: "Каунасский уезд (Kauno apskritis)" },
            { value: "KLAIPEDA", label: "Клайпедский уезд (Klaipėdos apskritis)" },
            { value: "MARIJAMPOLE", label: "Мариямпольский уезд (Marijampolės apskritis)" },
            { value: "PANEVEZYS", label: "Паневежский уезд (Panevėžio apskritis)" },
            { value: "SIAULIAI", label: "Шяуляйский уезд (Šiaulių apskritis)" },
            { value: "TAURAGE", label: "Таурагский уезд (Tauragės apskritis)" },
            { value: "TELŠIAI", label: "Тельшяйский уезд (Telšių apskritis)" },
            { value: "UTENA", label: "Утенский уезд (Utenos apskritis)" },
            { value: "VILNIUS", label: "Вильнюсский уезд (Vilniaus apskritis)" }
        ]
    },
    {
        value: "ESTONIA",
        label: "Эстония",
        regions: [
            { value: "HARJU", label: "Харьюмаа (Harju)" },
            { value: "HIIU", label: "Хийумаа (Hiiu)" },
            { value: "IDA_VIRU", label: "Ида-Вирумаа (Ida-Viru)" },
            { value: "JOGEVA", label: "Йыгевамаа (Jõgeva)" },
            { value: "JARVA", label: "Ярвамаа (Järva)" },
            { value: "LAANE", label: "Ляэнемаа (Lääne)" },
            { value: "LAANE_VIRU", label: "Ляэне-Вирумаа (Lääne-Viru)" },
            { value: "PARN", label: "Пярнумаа (Pärnu)" },
            { value: "POLVA", label: "Пылвамаа (Põlva)" },
            { value: "RAPLA", label: "Рапламаа (Rapla)" },
            { value: "SAARE", label: "Сааремаа (Saare)" },
            { value: "TARTU", label: "Тартумаа (Tartu)" },
            { value: "VALGA", label: "Валгамаа (Valga)" },
            { value: "VILJANDI", label: "Вильяндимаа (Viljandi)" },
            { value: "VORU", label: "Вырумаа (Võru)" }
        ]
    },
    {
        value: "BULGARIA",
        label: "Болгария",
        regions: [
            { value: "BLAGOEVGRAD", label: "Благоевградская область" },
            { value: "BURGAS", label: "Бургасская область" },
            { value: "DOBRICH", label: "Добричская область" },
            { value: "GABROVO", label: "Габровская область" },
            { value: "HASKOVO", label: "Хасковская область" },
            { value: "KARDZHALI", label: "Кырджалийская область" },
            { value: "KYUSTENDIL", label: "Кюстендилская область" },
            { value: "LOVECH", label: "Ловечская область" },
            { value: "MONTANA", label: "Монтанская область" },
            { value: "PAZARDZHIK", label: "Пазарджикская область" },
            { value: "PERNIK", label: "Перникская область" },
            { value: "PLEVEN", label: "Плевенская область" },
            { value: "PLOVDIV", label: "Пловдивская область" },
            { value: "RAZGRAD", label: "Разградская область" },
            { value: "RUSE", label: "Русенская область" },
            { value: "SHUMEN", label: "Шуменская область" },
            { value: "SILISTRA", label: "Силистренская область" },
            { value: "SLIVEN", label: "Сливенская область" },
            { value: "SMOLYAN", label: "Смолянская область" },
            { value: "SOFIA_CITY", label: "г. София (столица)" },
            { value: "SOFIA_PROVINCE", label: "Софийская область" },
            { value: "STARA_ZAGORA", label: "Старозагорская область" },
            { value: "TSAREVO", label: "Тырговиштская область" },
            { value: "VARNA", label: "Варненская область" },
            { value: "VELIKO_TARNOVO", label: "Великотырновская область" },
            { value: "VIDIN", label: "Видинская область" },
            { value: "VRATSA", label: "Врачанская область" },
            { value: "YAMBOL", label: "Ямболская область" }
        ]
    },
    {
        value: "ROMANIA",
        label: "Румыния",
        regions: [
            { value: "ALBA", label: "Алба" },
            { value: "ARAD", label: "Арад" },
            { value: "ARGES", label: "Арджеш" },
            { value: "BACAU", label: "Бакэу" },
            { value: "BIHOR", label: "Бихор" },
            { value: "BISTRITA_NASAUD", label: "Бистрица-Нэсэуд" },
            { value: "BOTOSANI", label: "Ботошани" },
            { value: "BRAILA", label: "Брэила" },
            { value: "BRASOV", label: "Брашов" },
            { value: "BUZAU", label: "Бузэу" },
            { value: "CALARASI", label: "Кэлэраши" },
            { value: "CARAS_SEVERIN", label: "Караш-Северин" },
            { value: "CLUJ", label: "Клуж" },
            { value: "CONSTANTA", label: "Констанца" },
            { value: "COVASNA", label: "Ковасна" },
            { value: "DAMBOVITA", label: "Дымбовица" },
            { value: "DOLJ", label: "Долж" },
            { value: "GALATI", label: "Галац" },
            { value: "GIURGIU", label: "Джурджу" },
            { value: "GORJ", label: "Горж" },
            { value: "HARGHITA", label: "Харгита" },
            { value: "HUNEDOARA", label: "Хунедоара" },
            { value: "IALOMITA", label: "Яломица" },
            { value: "IASI", label: "Яссы" },
            { value: "ILFOV", label: "Илфов" },
            { value: "MARAMURES", label: "Марамуреш" },
            { value: "MEHEDINTI", label: "Мехединць" },
            { value: "MURES", label: "Муреш" },
            { value: "NEAMT", label: "Нямц" },
            { value: "OLT", label: "Олт" },
            { value: "PRAHOVA", label: "Прахова" },
            { value: "SALAJ", label: "Сэлаж" },
            { value: "SATU_MARE", label: "Сату-Маре" },
            { value: "SIBIU", label: "Сибиу" },
            { value: "SUCEAVA", label: "Сучава" },
            { value: "TELEORMAN", label: "Телеорман" },
            { value: "TIMIS", label: "Тимиш" },
            { value: "TULCEA", label: "Тулча" },
            { value: "VASLUI", label: "Васлуй" },
            { value: "VALCEA", label: "Вылча" },
            { value: "VRANCEA", label: "Вранча" },
            { value: "BUCHAREST", label: "г. Бухарест (столица)" }
        ]
    },
    {
        value: "CROATIA",
        label: "Хорватия",
        regions: [
            { value: "ZAGREB", label: "г. Загреб (Grad Zagreb)" },
            { value: "BJELOVAR_BILOGORA", label: "Беловарско-Билогорская жупания" },
            { value: "BROD_POSAVINA", label: "Бродско-Посавская жупания" },
            { value: "DUBROVNIK_NERETVA", label: "Дубровачко-Неретванская жупания" },
            { value: "ISTRA", label: "Истрийская жупания" },
            { value: "KARLOVAC", label: "Карловацкая жупания" },
            { value: "KOPRIVNICA_KRIZEVCI", label: "Копривничко-Крижевцкая жупания" },
            { value: "KRAPINA_ZAGORJE", label: "Крапинско-Загорская жупания" },
            { value: "LIKA_SENJ", label: "Личко-Сенская жупания" },
            { value: "MEĐIMURJE", label: "Меджимурская жупания" },
            { value: "OSJEK_BARANJA", label: "Осиечко-Бараньская жупания" },
            { value: "POŽEGA_SLAVONIA", label: "Пожежско-Славонская жупания" },
            { value: "PRIMORJE_GORSKI", label: "Приморско-Горанская жупания" },
            { value: "ŠIBENIK_KNIN", label: "Шибенско-Книнская жупания" },
            { value: "SISAK_MOSLAVINA", label: "Сисачко-Мославинская жупания" },
            { value: "SPLIT_DALMATIA", label: "Сплитско-Далматинская жупания" },
            { value: "VARAŽDIN", label: "Вараждинская жупания" },
            { value: "VIROVITICA_PODRAVINA", label: "Вировитицко-Подравинская жупания" },
            { value: "VUKOVAR_SRIJEM", label: "Вуковарско-Сремская жупания" },
            { value: "ZADAR", label: "Задарская жупания" },
            { value: "ZAGREB_COUNTY", label: "Загребская жупания (округ вокруг столицы)" },
            { value: "KARLOVAC", label: "Карловацкая жупания" }
        ]
    },
    {
        value: "LUXEMBOURG",
        label: "Люксембург",
        regions: [
            { value: "CANTON_CAPELLEN", label: "Капеллен (Capellen)" },
            { value: "CANTON_CLERVAUX", label: "Клерво (Clervaux)" },
            { value: "CANTON_DIEKIRCH", label: "Дикирх (Diekirch)" },
            { value: "CANTON_ECHTERNACH", label: "Эхтернах (Echternach)" },
            { value: "CANTON_ESCH", label: "Эш-сюр-Альзетт (Esch-sur-Alzette)" },
            { value: "CANTON_GREVENMACHER", label: "Гревенмахер (Grevenmacher)" },
            { value: "CANTON_LUXEMBOURG", label: "Люксембург (Luxembourg)" },
            { value: "CANTON_MERSCH", label: "Мерш (Mersch)" },
            { value: "CANTON_REDMANGE", label: "Реданж (Redange)" },
            { value: "CANTON_REMICH", label: "Ремих (Remich)" },
            { value: "CANTON_VIANDEN", label: "Вианден (Vianden)" },
            { value: "CANTON_WILTZ", label: "Вильц (Wiltz)" }
        ]
    },
    {
        value: "USA",
        label: "США",
        regions: [
            { value: "AL", label: "Алабама" },
            { value: "AK", label: "Аляска" },
            { value: "AZ", label: "Аризона" },
            { value: "AR", label: "Арканзас" },
            { value: "CA", label: "Калифорния" },
            { value: "CO", label: "Колорадо" },
            { value: "CT", label: "Коннектикут" },
            { value: "DE", label: "Делавэр" },
            { value: "FL", label: "Флорида" },
            { value: "GA", label: "Джорджия" },
            { value: "HI", label: "Гавайи" },
            { value: "ID", label: "Айдахо" },
            { value: "IL", label: "Иллинойс" },
            { value: "IN", label: "Индиана" },
            { value: "IA", label: "Айова" },
            { value: "KS", label: "Канзас" },
            { value: "KY", label: "Кентукки" },
            { value: "LA", label: "Луизиана" },
            { value: "ME", label: "Мэн" },
            { value: "MD", label: "Мэриленд" },
            { value: "MA", label: "Массачусетс" },
            { value: "MI", label: "Мичиган" },
            { value: "MN", label: "Миннесота" },
            { value: "MS", label: "Миссисипи" },
            { value: "MO", label: "Миссури" },
            { value: "MT", label: "Монтана" },
            { value: "NE", label: "Небраска" },
            { value: "NV", label: "Невада" },
            { value: "NH", label: "Нью-Гэмпшир" },
            { value: "NJ", label: "Нью-Джерси" },
            { value: "NM", label: "Нью-Мексико" },
            { value: "NY", label: "Нью-Йорк" },
            { value: "NC", label: "Северная Каролина" },
            { value: "ND", label: "Северная Дакота" },
            { value: "OH", label: "Огайо" },
            { value: "OK", label: "Оклахома" },
            { value: "OR", label: "Орегон" },
            { value: "PA", label: "Пенсильвания" },
            { value: "RI", label: "Род-Айленд" },
            { value: "SC", label: "Южная Каролина" },
            { value: "SD", label: "Южная Дакота" },
            { value: "TN", label: "Теннесси" },
            { value: "TX", label: "Техас" },
            { value: "UT", label: "Юта" },
            { value: "VT", label: "Вермонт" },
            { value: "VA", label: "Виргиния" },
            { value: "WA", label: "Вашингтон" },
            { value: "WV", label: "Западная Виргиния" },
            { value: "WI", label: "Висконсин" },
            { value: "WY", label: "Вайоминг" },
            { value: "DC", label: "Округ Колумбия (Вашингтон D.C.)" }
        ]
    },
    {
        value: "CANADA",
        label: "Канада",
        regions: [
            // Провинции (Provinces)
            { value: "ALBERTA", label: "Альберта" },
            { value: "BRITISH_COLUMBIA", label: "Британская Колумбия" },
            { value: "MANITOBA", label: "Манитоба" },
            { value: "NEW_BRUNSWICK", label: "Нью-Брансуик" },
            { value: "NEWFOUNDLAND_LABRADOR", label: "Ньюфаундленд и Лабрадор" },
            { value: "NOVA_SCOTIA", label: "Новая Шотландия" },
            { value: "ONTARIO", label: "Онтарио" },
            { value: "PRINCE_EDWARD_ISLAND", label: "Остров Принца Эдуарда" },
            { value: "QUEBEC", label: "Квебек" },
            { value: "SASKATCHEWAN", label: "Саскачеван" },
            // Территории (Territories)
            { value: "NORTHWEST_TERRITORIES", label: "Северо-Западные территории" },
            { value: "NUNAVUT", label: "Нунавут" },
            { value: "YUKON", label: "Юкон" }
        ]
    },
    {
        value: "AUSTRALIA",
        label: "Австралия",
        regions: [
            // Штаты
            { value: "NEW_SOUTH_WALES", label: "Новый Южный Уэльс (New South Wales)" },
            { value: "VICTORIA", label: "Виктория (Victoria)" },
            { value: "QUEENSLAND", label: "Квинсленд (Queensland)" },
            { value: "WESTERN_AUSTRALIA", label: "Западная Австралия (Western Australia)" },
            { value: "SOUTH_AUSTRALIA", label: "Южная Австралия (South Australia)" },
            { value: "TASMANIA", label: "Тасмания (Tasmania)" },

            // Территории
            { value: "AUSTRALIAN_CAPITAL_TERRITORY", label: "Австралийская столичная территория (ACT)" },
            { value: "NORTHERN_TERRITORY", label: "Северная территория (Northern Territory)" }
        ]
    },
    {
        value: "NEW_ZEALAND",
        label: "Новая Зеландия",
        regions: [
            { value: "NORTHLAND", label: "Нортленд (Northland)" },
            { value: "AUCKLAND", label: "Окленд (Auckland)" },
            { value: "WAIKATO", label: "Вайкато (Waikato)" },
            { value: "BAY_OF_PLENTY", label: "Бей-оф-Пленти (Bay of Plenty)" },
            { value: "GISBORNE", label: "Гисборн (Gisborne)" },
            { value: "HAWKES_BAY", label: "Хокс-Бей (Hawke's Bay)" },
            { value: "TARANAKI", label: "Таранаки (Taranaki)" },
            { value: "MANAWATU_WANGANUI", label: "Манавату — Уангануи (Manawatū-Whanganui)" },
            { value: "WELLINGTON", label: "Веллингтон (Wellington)" },

            { value: "TASMAN", label: "Тасман (Tasman)" },
            { value: "NELSON", label: "Нельсон (Nelson)" },
            { value: "MARLBOROUGH", label: "Марлборо (Marlborough)" },
            { value: "WEST_COAST", label: "Уэст-Кост (West Coast)" },
            { value: "CANTERBURY", label: "Кентербери (Canterbury)" },
            { value: "OTAGO", label: "Отаго (Otago)" },
            { value: "SOUTHLAND", label: "Саутленд (Southland)" }
        ]
    },
    {
        value: "JAPAN",
        label: "Япония",
        regions: [
            { value: "HOKKAIDO", label: "Хоккайдо" },
            { value: "AOMORI", label: "Аомори" },
            { value: "IWATE", label: "Иватэ" },
            { value: "MIYAGI", label: "Мияги" },
            { value: "AKITA", label: "Акита" },
            { value: "YAMAGATA", label: "Ямагата" },
            { value: "FUKUSHIMA", label: "Фукусима" },
            { value: "IBARAKI", label: "Ибараки" },
            { value: "TOCHIGI", label: "Тотиги" },
            { value: "GUNMA", label: "Гумма" },
            { value: "SAITAMA", label: "Сайтама" },
            { value: "CHIBA", label: "Тиба" },
            { value: "TOKYO", label: "Токио (столица)" },
            { value: "KANAGAWA", label: "Канагава" },
            { value: "NIIGATA", label: "Ниигата" },
            { value: "TOYAMA", label: "Тояма" },
            { value: "ISHIKAWA", label: "Исикава" },
            { value: "FUKUI", label: "Фукуи" },
            { value: "YAMANASHI", label: "Яманаси" },
            { value: "NAGANO", label: "Нагано" },
            { value: "GIFU", label: "Гифу" },
            { value: "SHIZUOKA", label: "Сидзуока" },
            { value: "AICHI", label: "Айти" },
            { value: "MIE", label: "Мие" },
            { value: "SHIGA", label: "Сига" },
            { value: "KYOTO", label: "Киото" },
            { value: "OSAKA", label: "Осака" },
            { value: "HYOGO", label: "Хёго" },
            { value: "NARA", label: "Нара" },
            { value: "WAKAYAMA", label: "Вакаяма" },
            { value: "TOTTORI", label: "Тоттори" },
            { value: "SHIMANE", label: "Симане" },
            { value: "OKAYAMA", label: "Окаяма" },
            { value: "HIROSHIMA", label: "Хиросима" },
            { value: "YAMAGUCHI", label: "Ямагути" },
            { value: "TOKUSHIMA", label: "Токусима" },
            { value: "KAGAWA", label: "Кагава" },
            { value: "EHIME", label: "Эхиме" },
            { value: "KOCHI", label: "Коти" },
            { value: "FUKUOKA", label: "Фукуока" },
            { value: "SAGA", label: "Сага" },
            { value: "NAGASAKI", label: "Нагасаки" },
            { value: "KUMAMOTO", label: "Кумамото" },
            { value: "OITA", label: "Оита" },
            { value: "MIYAZAKI", label: "Миядзаки" },
            { value: "KAGOSHIMA", label: "Кагосима" },
            { value: "OKINAWA", label: "Окинава" }
        ]
    },
    {
        value: "SOUTH_KOREA",
        label: "Южная Корея",
        regions: [
            // Специальные и столичные города
            { value: "SEOUL", label: "Сеул (특별시)" },
            { value: "BUSAN", label: "Пусан (광역시)" },
            { value: "DAEGU", label: "Тэгу (광역시)" },
            { value: "INCHEON", label: "Инчхон (광역시)" },
            { value: "GWANGJU", label: "Кванджу (광역시)" },
            { value: "DAEJEON", label: "Тэджон (광역시)" },
            { value: "ULSAN", label: "Ульсан (광역시)" },
            { value: "SEJONG", label: "Седжон (특별자치시)" },

            // Провинции
            { value: "GYEONGGI", label: "Кёнгидо (경기도)" },
            { value: "GANGWON", label: "Канвондо (강원도)" },
            { value: "CHUNGCHEONGBUK", label: "Северная Чхунчхон (충청북도)" },
            { value: "CHUNGCHEONGNAM", label: "Южная Чхунчхон (충청남도)" },
            { value: "JEOLLABUK", label: "Северная Чолла (전라북도)" },
            { value: "JEOLLANAM", label: "Южная Чолла (전라남도)" },
            { value: "GYEONGBUK", label: "Северный Кёнсан (경상북도)" },
            { value: "GYEONGNAM", label: "Южный Кёнсан (경상남도)" },
            { value: "JEJU", label: "Чеджудо (제주특별자치도)" }
        ]
    },
    {
        value: "SINGAPORE",
        label: "Сингапур",
        regions: [
            { value: "CENTRAL_REGION", label: "Центральный регион" },
            { value: "EAST_REGION", label: "Восточный регион" },
            { value: "NORTH_REGION", label: "Северный регион" },
            { value: "NORTH_EAST_REGION", label: "Северо-восточный регион" },
            { value: "WEST_REGION", label: "Западный регион" }
        ]
    },
    {
        value: "ISRAEL",
        label: "Израиль",
        regions: [
            { value: "NORTH", label: "Северный округ (Mehoz HaTzafon)" },
            { value: "HAIFA", label: "Хайфский округ (Mehoz Heifa)" },
            { value: "CENTER", label: "Центральный округ (Mehoz HaMerkaz)" },
            { value: "TEL_AVIV", label: "Округ Тель-Авив (Mehoz Tel Aviv)" },
            { value: "JERUSALEM", label: "Иерусалимский округ (Mehoz Yerushalayim)" },
            { value: "SOUTH", label: "Южный округ (Mehoz HaDarom)" },
            { value: "JUDEA_SAMARIA", label: "Округ Иудея и Самария (Mehoz Yehuda VeShomron)" }
        ]
    },
    {
        value: "UNITED_ARAB_EMIRATES",
        label: "ОАЭ",
        regions: [
            { value: "ABU_DHABI", label: "Абу-Даби (Abu Dhabi)" },
            { value: "DUBAI", label: "Дубай (Dubai)" },
            { value: "SHARJAH", label: "Шарджа (Sharjah)" },
            { value: "AJMAN", label: "Аджман (Ajman)" },
            { value: "UMM_AL_QUWAIN", label: "Умм-аль-Кувейн (Umm Al-Quwain)" },
            { value: "RAS_AL_KHAIMAH", label: "Рас-эль-Хайма (Ras Al Khaimah)" },
            { value: "FUJAIRAH", label: "Фуджейра (Fujairah)" }
        ]
    },
    {
        value: "TURKEY",
        label: "Турция",
        regions: [
            { value: "ADANA", label: "Адана" },
            { value: "ADIYAMAN", label: "Адыяман" },
            { value: "AFYONKARAHISAR", label: "Афьонкарахисар" },
            { value: "AGRI", label: "Агры" },
            { value: "AMASYA", label: "Амасья" },
            { value: "ANKARA", label: "Анкара" },
            { value: "ANTALYA", label: "Анталья" },
            { value: "ARTVIN", label: "Артвин" },
            { value: "AYDIN", label: "Айдын" },
            { value: "BALIKESIR", label: "Балыкесир" },
            { value: "BILECIK", label: "Биледжик" },
            { value: "BINGOL", label: "Бингёль" },
            { value: "BITLIS", label: "Битлис" },
            { value: "BOLU", label: "Болу" },
            { value: "BURDUR", label: "Бурдур" },
            { value: "BURSA", label: "Бурса" },
            { value: "CANAKKALE", label: "Чанаккале" },
            { value: "CANKIRI", label: "Чанкыры" },
            { value: "CORUM", label: "Чорум" },
            { value: "DENIZLI", label: "Денизли" },
            { value: "DIYARBAKIR", label: "Диярбакыр" },
            { value: "EDIRNE", label: "Эдирне" },
            { value: "ELAZIG", label: "Элязыг" },
            { value: "ERZINCAN", label: "Эрзинджан" },
            { value: "ERZURUM", label: "Эрзурум" },
            { value: "ESKISEHIR", label: "Эскишехир" },
            { value: "GAZIANTEP", label: "Газиантеп" },
            { value: "GIRESUN", label: "Гиресун" },
            { value: "GUMUSHANE", label: "Гюмюшхане" },
            { value: "HAKKARI", label: "Хаккяри" },
            { value: "HATAY", label: "Хатай" },
            { value: "ISPARTA", label: "Ыспарта" },
            { value: "MERSIN", label: "Мерсин" },
            { value: "ISTANBUL", label: "Стамбул" },
            { value: "IZMIR", label: "Измир" },
            { value: "KARS", label: "Карс" },
            { value: "KASTAMONU", label: "Кастамону" },
            { value: "KAYSERI", label: "Кайсери" },
            { value: "KIRKLARELI", label: "Кыркларели" },
            { value: "KIRSEHIR", label: "Кыршехир" },
            { value: "KOCAELI", label: "Коджаэли" },
            { value: "KONYA", label: "Конья" },
            { value: "KUTAHYA", label: "Кютахья" },
            { value: "MALATYA", label: "Малатья" },
            { value: "MANISA", label: "Маниса" },
            { value: "KAHRAMANMARAS", label: "Кахраманмараш" },
            { value: "MARDIN", label: "Мардин" },
            { value: "MUGLA", label: "Мугла" },
            { value: "MUS", label: "Муш" },
            { value: "NEVSEHIR", label: "Невшехир" },
            { value: "NIGDE", label: "Нигде" },
            { value: "ORDU", label: "Орду" },
            { value: "RIZE", label: "Ризе" },
            { value: "SAKARYA", label: "Сакарья" },
            { value: "SAMSUN", label: "Самсун" },
            { value: "SIIRT", label: "Сиирт" },
            { value: "SINOP", label: "Синоп" },
            { value: "SIVAS", label: "Сивас" },
            { value: "TEKIRDAG", label: "Текирдаг" },
            { value: "TOKAT", label: "Токат" },
            { value: "TRABZON", label: "Трабзон" },
            { value: "TUNCELI", label: "Тунджели" },
            { value: "SANLIURFA", label: "Шанлыурфа" },
            { value: "USAK", label: "Ушак" },
            { value: "VAN", label: "Ван" },
            { value: "YOZGAT", label: "Йозгат" },
            { value: "ZONGULDAK", label: "Зонгулдак" },
            { value: "AKSARAY", label: "Аксарай" },
            { value: "BAYBURT", label: "Байбурт" },
            { value: "KARAMAN", label: "Караман" },
            { value: "KIRIKKALE", label: "Кырыккале" },
            { value: "BATMAN", label: "Батман" },
            { value: "SIRNAK", label: "Шырнак" },
            { value: "BARTIN", label: "Бартын" },
            { value: "ARDAHAN", label: "Ардахан" },
            { value: "IGDIR", label: "Ыгдыр" },
            { value: "YALOVA", label: "Ялова" },
            { value: "KARABUK", label: "Карабюк" },
            { value: "KILIS", label: "Килис" },
            { value: "OSMANIYE", label: "Османие" },
            { value: "DUZCE", label: "Дюздже" }
        ]
    }
];
export default COUNTRIES_WITH_REGIONS;