// ChatButton.jsx
import React from 'react';
import styles from './ChatButton.module.css';
import { openChatInNewTab } from '../../utils/openChatInNewTab';

const ChatButton = ({ userId, type = "client" }) => {
    const labelMap = {
        client: "Чат с клиентом",
        seller: "Чат с продавцом",
        support: "Чат с поддержкой"
    };

    return (
        <div className={styles.chatWrapper}>
            <button
                className={styles.chatButton}
                onClick={() => openChatInNewTab(userId)}
            >
                💬 Открыть чат
            </button>
            <p className={styles.chatLabel}>{labelMap[type] || "Чат"}</p>
        </div>
    );
};

export default ChatButton;