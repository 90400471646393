import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

const COLORS = {
    "5%": "#4a90e2",
    "7%": "#00C49F",
    "10%": "#FFBB28"
};

const AmbassadorByDiscountChart = ({ purchaseHistory }) => {
    const grouped = {};

    for (const p of purchaseHistory) {
        const name = p.name;
        const discountKey = `${p.discount}%`;
        const earned = Math.round((p.amount * p.discount) / 100);

        if (!grouped[name]) grouped[name] = { name };
        grouped[name][discountKey] = (grouped[name][discountKey] || 0) + earned;
    }

    const data = Object.values(grouped);

    if (data.length === 0) return null;

    return (
        <div style={{ width: "100%", maxWidth: "700px", margin: "40px auto" }}>
            <h3 style={{ textAlign: "center", color: "#111349" }}>
                Доход амбассадоров по скидкам
            </h3>

            <div style={{ overflowX: "auto", overflowY: "hidden" }}>
                <div style={{ minWidth: "700px", width: `${data.length * 100}px` }}>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={data}
                            margin={{ top: 60, right: 30, left: 30, bottom: 100 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="name"
                                angle={-30}
                                textAnchor="end"
                                interval={0}
                                height={80}
                                padding={{ left: 20, right: 20 }}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} />
                            {Object.keys(COLORS).map((key, index) => (
                                <Bar key={index} dataKey={key} fill={COLORS[key]} />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default AmbassadorByDiscountChart;