import { useEffect, useState } from "react";
import styles from "./OrderFlow.module.css";
import ORDER_STATUS from "../../constants/orderStatus";
import OrderStatusContent from "./OrderStatusContent";
import PaymentModal from "../../components/Payment/PaymentModal";

const OrderFlow = ({ product: propProduct }) => {
    // Прогрузка данных с Бекенда
    const defaultProduct = {
        id: "prod123",
        name: "Apple Watch Ultra 2",
        price: 289000,
        currency: "₸",
    };
    const product = propProduct || defaultProduct;
    const userId = "user_123"; // временно хард-код

    const [order, setOrder] = useState(null);
    const [isPaying, setIsPaying] = useState(false);
    const [showDetails, setShowDetails] = useState(false); // Dropdown: Детали курьера
    const [showTokenInfo, setShowTokenInfo] = useState(false); // Dropdown: Токен
    const [showPaymentModal, setShowPaymentModal] = useState(false); // Modal: Оплата

    useEffect(() => {
        // 🔄 Имитация запроса на бэкенд (заменить на fetch/axios)
        const fetchOrder = async () => {
            // const response = await fetch(`/api/orders?userId=${userId}&productId=${product.id}`);
            // const data = await response.json();
            // setOrder(data);

            // Пока заглушка:
            setOrder({
                id: "order_123",
                userId,
                product,
                status: ORDER_STATUS.PAYMENT_DECLINED,
                payment: {
                    invoiceId: "inv_456",
                    amount: product.price,
                    currency: product.currency,
                    isPaid: false
                },
                delivery: {
                    status: "en_route",
                    pickupCode: "754913",
                    courier: {
                        name: "Алихан",
                        phone: "+7 777 123 45 67"
                    },
                    attachments: [
                        {
                            type: "photo",
                            url: "/mock-package.jpg",
                            comment: "Упаковано плотно, без повреждений."
                        }
                    ]
                }
            });
        };

        fetchOrder();
    }, [product.id]);

    // Дизайн
    const statuses = [
        { id: "requested", label: "Заявка" },
        { id: "paid", label: "Оплата" },
        { id: "shipped", label: "В пути" },
        { id: "delivered", label: "Доставлено" },
    ];
    const [selectedStatus, setSelectedStatus] = useState(null);

    const handlePayment = () => {
        setShowPaymentModal(true); // открываем модалку вместо прямой оплаты
    };
    const handleDecline = () => {
        setOrder(prev => ({
            ...prev,
            payment: {
                ...prev.payment,
                isPaid: false  // Отмечаем платёж как не выполненный
            },
            status: ORDER_STATUS.DECLINED  // Обновляем статус заказа на "Отклонён"
        }));
    };
    const handlePaymentConfirm = ({ bank, type }) => {
        console.log("Пользователь выбрал:", bank, type);
        setIsPaying(true);
        setTimeout(() => {
            setOrder(prev => ({
                ...prev,
                payment: { ...prev.payment, isPaid: true },
                status: ORDER_STATUS.SHIPPED
            }));
            setIsPaying(false);
        }, 1500);
    };


    return (
        <>
            <div className={styles.orderFlowWrapper}>
                {order && order.status !== ORDER_STATUS.NONE && (
                    <div className={styles.statusLine}>
                        {statuses.map((s) => {
                            const isActive = s.id === selectedStatus;

                            return (
                                <div
                                    key={s.id}
                                    className={`${styles.statusStep} 
                                ${isActive ? styles.active : ""}`}
                                    onClick={() => setSelectedStatus(s.id)}
                                >
                                    {s.label}
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className={styles.statusContent}>
                    {!order && <p>Загрузка...</p>}

                    {order && selectedStatus === "requested" && (
                        <>
                            {[
                                ORDER_STATUS.REQUESTED,
                                ORDER_STATUS.INVOICE_SENT
                            ].includes(order.status) && (
                                    <OrderStatusContent
                                        order={order}
                                        product={product}
                                        isPaying={isPaying}
                                        showDetails={showDetails}
                                        setShowDetails={setShowDetails}
                                        handlePayment={handlePayment}
                                        handleDecline={handleDecline}
                                        showTokenInfo={showTokenInfo}
                                        setShowTokenInfo={setShowTokenInfo}
                                    />
                                )}
                        </>
                    )}

                    {order && selectedStatus === "paid" && (
                        <>
                            {[
                                ORDER_STATUS.PAYMENT_DECLINED,
                                ORDER_STATUS.PAID
                            ].includes(order.status) && (
                                    <OrderStatusContent
                                        order={order}
                                        product={product}
                                        isPaying={isPaying}
                                        showDetails={showDetails}
                                        setShowDetails={setShowDetails}
                                        handlePayment={handlePayment}
                                        handleDecline={handleDecline}
                                        showTokenInfo={showTokenInfo}
                                        setShowTokenInfo={setShowTokenInfo}
                                    />
                                )}
                        </>
                    )}

                    {order && selectedStatus === "shipped" && (
                        <>
                            {[
                                ORDER_STATUS.DELIVERY_PENDING,
                                ORDER_STATUS.DELIVERY_ON_THE_WAY,
                                ORDER_STATUS.DELIVERED
                            ].includes(order.status) && (
                                    <OrderStatusContent
                                        order={order}
                                        product={product}
                                        isPaying={isPaying}
                                        showDetails={showDetails}
                                        setShowDetails={setShowDetails}
                                        handlePayment={handlePayment}
                                        handleDecline={handleDecline}
                                        showTokenInfo={showTokenInfo}
                                        setShowTokenInfo={setShowTokenInfo}
                                    />
                                )}
                        </>
                    )}

                    {order && selectedStatus === "delivered" && (
                        <>
                            {[
                                ORDER_STATUS.RETURNED,
                                ORDER_STATUS.CANCELLED
                            ].includes(order.status) && (
                                    <OrderStatusContent
                                        order={order}
                                        product={product}
                                        isPaying={isPaying}
                                        showDetails={showDetails}
                                        setShowDetails={setShowDetails}
                                        handlePayment={handlePayment}
                                        handleDecline={handleDecline}
                                        showTokenInfo={showTokenInfo}
                                        setShowTokenInfo={setShowTokenInfo}
                                    />
                                )}
                        </>
                    )}
                </div>
            </div>

            <PaymentModal
                isOpen={showPaymentModal}
                onClose={() => setShowPaymentModal(false)}
                onConfirm={handlePaymentConfirm}
                banks={product.paymentMethods || []}
                product={product}
            />
        </>
    );
};

export default OrderFlow;