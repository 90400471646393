// OrderStatusContent.jsx
import React from "react";
import ORDER_STATUS from "../../constants/orderStatus";
import styles from "./OrderStatusContent.module.css";


const OrderStatusContent = ({
    order,
    product,
    isPaying,
    handlePayment,
    handleDecline,
    showDetails,
    setShowDetails,
    showTokenInfo,
    setShowTokenInfo
}) => {
    switch (order.status) {
        case ORDER_STATUS.NONE:
            return (
                <></>
            );
        case ORDER_STATUS.REQUESTED:
            return (
                <>
                    <h3>📝 Заявка оформлена</h3>
                    <p>Ожидайте подтверждения от продавца. После подтверждения будет выставлен счёт на оплату.</p>
                </>
            );
        case ORDER_STATUS.INVOICE_SENT:
            return (
                <>
                    <h3>📝 Счёт выставлен</h3>
                    <p>Продавец выставил счёт, вы можете перейти к оплате.</p>
                </>
            );
        case ORDER_STATUS.PAYMENT_DECLINED:
            return (
                <>
                    <h3>💰 Оплата</h3>
                    <p>Продавец выставил счёт. Подтвердите оплату или откажитесь.</p>
                    <p><strong>Сумма:</strong> {order.payment.amount} {order.payment.currency}</p>
                    <div className={styles.buttonRow}>
                        {isPaying ? (
                            <p>🔄 Обработка оплаты...</p>
                        ) : (
                            <button onClick={handlePayment} className={styles.acceptBtn}>✅ Оплатить</button>
                        )}
                        <button onClick={handleDecline} className={styles.rejectBtn}>❌ Отказаться</button>
                    </div>

                    {order.payment.isPaid === false && <p className={styles.declined}>Вы отказались от оплаты. Заказ отменён.</p>}
                </>
            );
        case ORDER_STATUS.PAID:
            return (
                <>
                    <h3>✅ Оплата подтверждена</h3>
                    <p>Ожидайте передачу товара в доставку.</p>
                </>


            );
        case ORDER_STATUS.DELIVERY_PENDING:
            return (
                <>
                    <h3>🚚 Ожидается передача товара в доставку</h3>
                    <p>Продавец ещё не передал товар курьеру.</p>
                </>
            );
        case ORDER_STATUS.DELIVERY_ON_THE_WAY:
            return (
                <>
                    <h3>🚚 Товар в пути</h3>
                    <p>Курьер в пути. Ожидайте доставку.</p>

                    <div className={styles.expandSection}>
                        <p>🛠 Продавец может прикрепить фото отправки:</p>

                        <button onClick={() => setShowDetails(!showDetails)} className={styles.expandBtn}>
                            {showDetails ? "Скрыть детали ▲" : "Показать детали ▼"}
                        </button>

                        {showDetails && order.delivery.attachments.map((a, i) => (
                            <div key={i} className={styles.photoSection}>
                                <p><strong>{a.type === "photo" ? "Фото упаковки:" : "Файл:"}</strong></p>
                                <img src={a.url} alt="Доказательство" className={styles.packagePhoto} />
                                {a.comment && <p><strong>Комментарий:</strong> {a.comment}</p>}
                            </div>
                        ))}
                    </div>

                    <div className={styles.courierBlock}>
                        <p><strong>Курьер:</strong> {order.delivery.courier.name}</p>
                        <p><strong>Телефон:</strong> {order.delivery.courier.phone}</p>
                        <p><strong>Код для получения:</strong></p>
                        <div className={styles.codeBox}>{order.delivery.pickupCode}</div>
                    </div>
                </>
            );
        case ORDER_STATUS.DELIVERED:
            return (
                <>
                    <h3>📦 Товар доставлен</h3>
                    <p><strong>Название:</strong> {product.name}</p>
                    <p><strong>Цена:</strong> {product.price} {product.currency}</p>
                    <button className={styles.returnBtn} onClick={() => setShowTokenInfo(prev => !prev)}>
                        🔄 Оформить возврат
                    </button>

                    {showTokenInfo && (
                        <div className={styles.tokenContent}>
                            <p>
                                Пожалуйста, вставьте данный токен в личный кабинет <code>ad_SERVLET</code> курьера.
                                Если курьер ещё не зарегистрирован, помогите ему создать учётную запись, указав ваш логин при регистрации.
                            </p>

                            <p>
                                После активации токена в личном кабинете <code>ad_SERVLET</code> в разделе <code>"Курьер"</code> отобразится страница с адресом доставки.
                                На этой странице будет поле для ввода <strong>кода подтверждения</strong>, который курьер получит от покупателя при передаче товара.
                            </p>

                            <p className={styles.tokenWarning}>
                                ⚠️ <strong>Важно:</strong> только после ввода этого кода средства за доставку будут автоматически перечислены курьеру.
                            </p>

                            <div className={styles.deliveryDataInputs}>
                                <label className={styles.labeledInput}>
                                    <span>Имя курьера:</span>
                                    <input type="text" id="courierName" className={styles.input} placeholder="Имя курьера" required />
                                </label>
                                <label className={styles.labeledInput}>
                                    <span>Номер мобильного телефона:</span>
                                    <input type="text" id="courierPhone" className={styles.input} placeholder="Номер мобильного телефона" required />
                                </label>
                                <label className={styles.labeledInput}>
                                    <span>Гос.номер транспорта:</span>
                                    <input type="text" id="courierCarNumber" className={styles.input} placeholder="Гос.номер транспорта" required />
                                </label>
                                <label className={styles.labeledInput}>
                                    <span>Марка и модель транспорта:</span>
                                    <input type="text" id="courierCarModel" className={styles.input} placeholder="Марка и модель" required />
                                </label>
                                <label className={styles.labeledInput}>
                                    <span>Цвет транспорта:</span>
                                    <input type="text" id="courierCarModel" className={styles.input} placeholder="Цвет транспорта" required />
                                </label>
                                <label className={styles.labeledInput}>
                                    <span>Фотография курьера:</span>
                                    <input type="file" id="courierPhoto" className={styles.inputFile} accept="image/*" required />
                                </label>
                                <p>
                                    ⚠️ Необходимо приложить скриншот из приложения службы доставки, на котором будет виден курьер,
                                    а также (если есть) — марка и государственный номер транспорта.<br /><br />

                                    А также, при передаче товара курьеру также рекомендуется сделать фотографию с отображением транспорта
                                    и его гос.номера, если он используется.
                                </p>
                                <label className={styles.labeledInput}>
                                    <span>Служба доставки:</span>
                                    <select id="courierSource" className={styles.select} required>
                                        <option value="">Выберите службу доставки от:</option>
                                        <option value="yandex">Яндекс</option>
                                        <option value="indriver">Индрайвер</option>
                                        <option value="selfFound">Апару</option>
                                        <option value="selfFound">Юр.компания местное такси</option>
                                        <option value="selfFound">По договоренности</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    )}
                </>
            );
        case ORDER_STATUS.RETURNED:
            return (
                <>
                    <h3>🔄 Возврат оформлен</h3>
                    <p>Ожидайте подтверждение от продавца и инструкций.</p>
                </>
            );
        case ORDER_STATUS.CANCELLED:
            return (
                <>
                    <h3> ОТМЕНЕН </h3>
                    <p>Заказ отменен.</p>
                </>
            );
        default:
            return <p>❓ Неизвестный статус</p>;
    }

};

export default OrderStatusContent;