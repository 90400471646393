import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from './Dynamics.module.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const buildTree = (data, rootId) => {
  const map = {};

  // Создаем карту всех узлов
  data.forEach((item) => {
    map[item.id] = { ...item, children: [], level: 0, expanded: true }; // Инициализируем уровень и expanded
  });

  // Рекурсивная функция для построения дерева
  const addChildren = (node) => {
    node.referrals.forEach((referralId) => {
      if (map[referralId]) {
        const child = map[referralId];
        child.level = node.level + 1; // Устанавливаем уровень на основе родителя
        node.children.push(child);
        addChildren(child); // Рекурсивно добавляем детей
      }
    });
  };

  // Начинаем с корневого узла
  const root = map[rootId];
  if (root) {
    addChildren(root);
  }

  return root ? [root] : [];
};

const SalesFunnel = () => {
  const [treeData, setTreeData] = useState([]);

  const getIncomePercentage = (level) => {
    if (level === 1) return 10;
    if (level === 2) return 7;
    if (level === 3) return 5;
    if (level === 4) return 3;
    if (level === 5) return 2;
    if (level >= 6 && level <= 17) return 1;
    return 0;
  };

  const toggleBlock = (id) => {
    const toggleNode = (nodes) =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, expanded: !node.expanded }
          : { ...node, children: toggleNode(node.children) }
      );

    setTreeData((prevTreeData) => toggleNode(prevTreeData));
  };

  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      alert("Вы не авторизованы!");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}:8082/api/v1/dynamics/getReferralTree`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Ошибка сети');
      }

      const data = await response.json();
      if (data && data.length > 0) {
        setTreeData(buildTree(data, data[0]?.id));
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxDepthRef = useRef(0); // ← храним максимальную глубину

  const renderTree = (nodes, depth = 0) => {
    if (!nodes || nodes.length === 0) return null;

    console.log(`Rendering depth: ${depth}, nodes:`, nodes.map(node => node.id));
    maxDepthRef.current = depth;

    return (
      <>
        <ul className={styles.tree} style={{ gridTemplateRows: `repeat(${nodes.length}, auto)` }}>
          {nodes.map((node, i) => (
            <li key={node.id} className={styles.treeItem} data-id={node.id} data-level={node.level}>

              <div className="treeItemContent">
                <span style={{ position: "relative", display: "inline-block" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: `${-20 - depth * 60}px`,
                      transform: "translateY(-50%)",
                      borderTop: "1px solid currentColor",
                      width: `${20 + depth * 60}px`,
                      height: "0",
                      pointerEvents: "none"
                    }}
                  ></div>

                  {i === 0 && (
                    <div
                      style={{
                        position: "absolute",
                        top: `${143 + depth * 100}%`, // поднимаем ниже центра, в зависимости от глубины
                        left: "50%",
                        width: "1px",
                        height: "10px",
                        backgroundColor: "#ccc",
                        transform: "translate(-50%, -50%)",
                        pointerEvents: "none",
                      }}
                    ></div>
                  )}

                  <span className={styles.spanItem} onClick={() => toggleBlock(node.id)}>{node.expanded ? "-" : "+"}</span>{" "}
                </span>
                {node.name}, {node.referredBy || "None"}
                {node.level !== 0 && ` -- ${getIncomePercentage(node.level)}%`}
              </div>

              {node.expanded && node.children && node.children.length > 0 && (
                renderTree(node.children, depth + 1)
              )}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentContainer}>
        <h1>Диаграмма воронки продаж</h1>
        <div className={styles.treeContainer}>
          <div className={styles.axisX}></div>
          <div className={styles.axisY}></div>
          <div
            className={styles.arrowX}
            style={{
              left: `${60 + maxDepthRef.current * 60}px`
            }}
          ></div>
          <div className={styles.arrowY}></div>

          {renderTree(treeData)}
        </div>
      </div>
    </div>
  );
};

export default SalesFunnel;