// CartModal.jsx
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './CartModal.module.css';
import ProductModal from './ProductModal';

export default function CartModal({
    isOpen,
    onClose,
    title,
    cartItems,
    removeFromCart,
    clearCart,
    totalPrice,
    currency,
    awaitingOrders,
    paidOrders
}) {
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [isSelfPickup, setIsSelfPickup] = useState(false);

    // Адрес в CartModel 
    const [addressError, setAddressError] = useState(false);
    const handlePayment = () => {
        if (!deliveryAddress.trim()) {
            setAddressError(true);
            setTimeout(() => setAddressError(false), 1000); // Удалить ошибку через 1 секунду
            return;
        }

        // Продолжить обработку платежа, если адрес введен
        alert("Оплата выбранных товаров");
    };

    const [selectedProduct, setSelectedProduct] = useState(null);
    const handleOpenModal = (item) => {
        setSelectedProduct(item);
    };
    const handleCloseModal = () => {
        setSelectedProduct(null);
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={styles.cartModalOverlay} onClick={onClose}>
            <div className={styles.cartModalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.cartModalHeader}>
                    <h2>{title}</h2>
                    <button className={styles.cartCloseButton} onClick={onClose}>✖</button>
                </div>
                <div className={styles.cartModalBody}>
                    {cartItems.length === 0 ? (
                        <p className={styles.cartEmpty}>Ваша корзина пуста</p>
                    ) : (
                        <>
                            <ul className={styles.cartList}>
                                {cartItems.map((item) => (
                                    <>
                                        <li key={item.id} className={styles.cartItem}>
                                            <input type="checkbox" className={styles.cartCheckbox} />
                                            <div
                                                className={styles.cartItemPreview}
                                                onClick={() => handleOpenModal(item)}
                                            >
                                                <img
                                                    src={item.image || "https://via.placeholder.com/60?text=📦"}
                                                    alt={item.name}
                                                    className={styles.cartItemImage}
                                                />
                                            </div>
                                            <div
                                                className={styles.cartItemInfo}
                                                onClick={() => handleOpenModal(item)}
                                            >
                                                <strong>{item.name}</strong>
                                                <div>{item.quantity} шт.</div>
                                                <div>{item.price} {item.currency}</div>
                                                <div>{item.city}, {item.country}</div>
                                            </div>
                                            <button
                                                className={styles.removeButton}
                                                onClick={() => removeFromCart(item.id)}
                                                title="Удалить товар"
                                            >
                                                🗑
                                            </button>
                                        </li>
                                    </>

                                ))}
                            </ul>

                            <div className={styles.cartTotal}>
                                Итого: <strong>{totalPrice.toFixed(2)} {currency}</strong>
                            </div>

                            <div className={styles.selfPickupCheckbox}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isSelfPickup}
                                        onChange={(e) => setIsSelfPickup(e.target.checked)}
                                    />
                                    <span>Я хочу забрать товар самовывозом</span>
                                </label>
                                <p className={styles.selfPickupNote}>
                                    Если выбран самовывоз, адрес доставки не требуется.<br /><br />

                                    Если вы выбрали заявку с самовывозом, продавец не сможет выставить счёт с резервированием средств покупателя на счёте ad_SERVLET.
                                    В этом случае расчёт между продавцом и покупателем будет произведён напрямую при самовывозе товара.
                                </p>
                            </div>

                            <input
                                type="text"
                                placeholder="Введите адрес доставки"
                                value={deliveryAddress}
                                onChange={(e) => setDeliveryAddress(e.target.value)}
                                disabled={isSelfPickup}
                                className={`${styles.addressInput} ${addressError ? styles.addressInputError : ''}`}
                            />

                            <div className={styles.cartActionButtons}>
                                <button
                                    className={styles.paySelectedButton}
                                    onClick={handlePayment}
                                >
                                    💳 Оформить выбранные
                                </button>
                                <button
                                    className={styles.clearCartButton}
                                    onClick={clearCart}
                                >
                                    🧹 Очистить корзину
                                </button>
                            </div>

                            <hr className={styles.cartSectionDivider} />

                            {/* Секция ожидающих заказов */}
                            {awaitingOrders?.length > 0 && (
                                <div className={styles.cartStatusSection}>
                                    <h4>🕓 В ожидании подтверждения</h4>
                                    <p className={styles.cartStatusNote}>
                                        Продавец скоро подтвердит товары. Мы уведомим вас.
                                    </p>
                                    <ul className={styles.cartList}>
                                        {awaitingOrders.map((item) => (
                                            <li key={item.id} className={styles.cartItem}>
                                                <div
                                                    className={styles.cartItemPreview}
                                                    onClick={() => handleOpenModal(item)}
                                                >
                                                    <img
                                                        src={item.image || "https://via.placeholder.com/60?text=📦"}
                                                        alt={item.name}
                                                        className={styles.cartItemImage}
                                                    />
                                                </div>
                                                <div
                                                    className={styles.cartItemInfo}
                                                    onClick={() => handleOpenModal(item)}
                                                >
                                                    <strong>{item.name}</strong>
                                                    <div>{item.quantity} шт.</div>
                                                    <div>{item.price} {item.currency}</div>
                                                    <div>{item.city}, {item.country}</div>
                                                    <div><strong>Адрес:</strong> {item.address || "—"}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Секция оплаченных заказов */}
                            {paidOrders?.length > 0 && (
                                <div className={styles.cartStatusSection}>
                                    <h4>✅ Оплаченные товары</h4>
                                    <p className={styles.cartStatusNote}>
                                        Заказы подтверждены. Код получения появится при отгрузке.
                                    </p>
                                    <ul className={styles.cartList}>
                                        {paidOrders.map((item) => (
                                            <li key={item.id} className={styles.cartItem}>
                                                <div
                                                    className={styles.cartItemPreview}
                                                    onClick={() => handleOpenModal(item)}
                                                >
                                                    <img
                                                        src={item.image || "https://via.placeholder.com/60?text=📦"}
                                                        alt={item.name}
                                                        className={styles.cartItemImage}
                                                    />
                                                </div>
                                                <div
                                                    className={styles.cartItemInfo}
                                                    onClick={() => handleOpenModal(item)}
                                                >
                                                    <strong>{item.name}</strong>
                                                    <div>{item.quantity} шт.</div>
                                                    <div>{item.price} {item.currency}</div>
                                                    <div>{item.city}, {item.country}</div>
                                                    <div><strong>Адрес:</strong> {item.address || "—"}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {selectedProduct && (
                <ProductModal
                    product={selectedProduct}
                    onClose={handleCloseModal}
                    addToCart={() => { }} // если нужно — передай addToCart
                />
            )}
        </div>,
        document.body
    );
}