import React, { useState, useEffect } from "react";
import styles from "./Partners.module.css";
import chipImage from "../../assets/svg/Partners/chip.svg";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Partners = () => {
    const [partners, setPartners] = useState([]);
    const [itemsPerSection, setItemsPerSection] = useState(4);
    const [searchQuery, setSearchQuery] = useState(""); // Стейт для поиска

    const fetchTestPartners = () => {
        const fakePartners = [
            {
                id: 1,
                login: "Компания 1",
                discount: 10,
                companyLogoBase64: null,
                website: "#",
                instagram: "#",
                isUserAmbassador: false,
                tariffStatus: "active", // тариф куплен, но мы не амбассадор
            },
            {
                id: 2,
                login: "Компания 2",
                discount: 15,
                companyLogoBase64: null,
                website: "#",
                instagram: "#",
                isUserAmbassador: true,
                tariffStatus: "active", // активный тариф и мы амбассадор
            },
            {
                id: 3,
                login: "Компания 3",
                discount: 5,
                companyLogoBase64: null,
                website: "#",
                instagram: "#",
                isUserAmbassador: false,
                tariffStatus: "inactive", // тариф неактивен
            }
        ];

        setPartners(fakePartners);
    };

    // Фетчинг списка партнёров
    const fetchPartners = async () => {
        try {
            const partnersResponse = await fetch(`${API_BASE_URL}:8081/api/v1/partners/list`);
            if (!partnersResponse.ok) throw new Error("Не удалось загрузить партнёров");
            const partnersData = await partnersResponse.json();

            const userResponse = await fetch(`${API_BASE_URL}:8081/api/v1/ambassadors/partners_list`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });

            if (!userResponse.ok) throw new Error("Ошибка при загрузке списка амбассадоров");
            const userData = await userResponse.json();

            // Создаём Set (множество) ID партнёров, где пользователь амбассадор
            const ambassadorSet = new Set(userData.ambassador_partners || []);

            // Добавляем флаг `isUserAmbassador` в каждый объект `partner`
            // TODO TODO TODO TODO TODO: Сделать так чтобы 2 отсортированных списка было, для быстрого поиска 
            const updatedPartners = partnersData.map((partner) => ({
                ...partner,
                isUserAmbassador: ambassadorSet.has(partner.id), // true/false
            }));

            setPartners(updatedPartners);
        } catch (err) {
            console.error("Ошибка при загрузке данных:", err);
        }
    };

    // Определяем количество карточек в секции в зависимости от ширины экрана
    useEffect(() => {
        const updateItemsPerSection = () => {
            setItemsPerSection(window.innerWidth < 768 ? 3 : 4);
        };

        updateItemsPerSection();
        window.addEventListener("resize", updateItemsPerSection);

        return () => {
            window.removeEventListener("resize", updateItemsPerSection);
        };
    }, []);

    // Загружаем список партнёров при монтировании
    useEffect(() => {
        // fetchPartners(); ❌ пока не используем
        fetchTestPartners(); // ✅ тестовый список
    }, []);

    // Фильтруем партнёров по `searchQuery`
    const filteredPartners = partners
        .filter((partner) => partner.login.toLowerCase().includes(searchQuery.toLowerCase()));

    // Разделение массива партнёров на секции
    const sections = [];
    for (let i = 0; i < filteredPartners.length; i += itemsPerSection) {
        sections.push(filteredPartners.slice(i, i + itemsPerSection));
    }

    return (
        <div className={styles["container-m"]}>
            <div className={styles["container-c"]}>
                <div className={styles.container}>
                    <div className={styles["cont"]}>
                        {/* Фильтры */}
                        <div>
                            <h1>Компании</h1>
                            <div className={styles.section}>
                                <div className={styles["search-container"]}>
                                    <input
                                        type="text"
                                        placeholder="Поиск компании"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <button>🔍</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Секция для партнёров */}
                <div className={styles.section}>
                    {sections.map((section, sectionIndex) => (
                        <div className={styles["partners-section"]} key={`section-${sectionIndex}`}>
                            {section.map((partner, index) => (
                                <div className={styles["partner-card"]} key={`partner-${sectionIndex}-${index}`}>
                                    <div className={styles["card-inner"]}>

                                        {/* Передняя сторона */}
                                        <div className={styles["card-front"]}>
                                            <img
                                                src={partner.companyLogoBase64
                                                    ? `data:image/png;base64,${partner.companyLogoBase64}`
                                                    : "https://via.placeholder.com/150"}
                                                alt={`Фото компании ${partner.login}`}
                                                className={styles["partner-logo"]}
                                            />
                                            <div className={styles["partner-card-content"]}>
                                                <span className={styles["partner-name"]}>{partner.login}</span>
                                                <span className={styles["partner-discount"]}>
                                                    Скидка {partner.discount}%
                                                </span>
                                            </div>
                                        </div>

                                        {/* Задняя сторона */}
                                        <div className={styles["card-back"]}>
                                            <h3 className={styles["partner-name"]}>{partner.login}</h3>
                                            <p className={styles["partner-description"]}>
                                                Краткая информация на карточке:
                                            </p>

                                            {/* 🔗 Блок ссылок */}
                                            <div className={styles["partner-links"]}>
                                                <a href={`/partners/${partner.id}`} className={styles["partner-link"]} target="_blank" rel="noopener noreferrer">
                                                    🔗 ad_SERVLET
                                                </a>
                                                <a href={partner.website || "#"} className={styles["partner-link"]} target="_blank" rel="noopener noreferrer">
                                                    🌍 Официальный сайт
                                                </a>
                                                <a href={partner.instagram || "#"} className={styles["partner-instagram"]} target="_blank" rel="noopener noreferrer">
                                                    <img src="/static/media/Instagram_logo.a12aff2b57b600b7b7dee8d0fc0f4a59.svg"
                                                        alt="Instagram Logo"
                                                        className={styles["instagram-logo"]}
                                                    />
                                                    Instagram
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
};

export default Partners;