import React from 'react';
import styles from './OrderSuccessCard.module.css';
import ChatButton from '../../components/ChatButton/ChatButton';

const OrderSuccessCard = ({ order, onReturn }) => {
    const invoice = parseFloat(order.invoice || 0);
    const commission = Math.round(invoice * 0.05);
    const received = invoice - commission;

    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <h4>✅ Сделка завершена</h4>
                <span className={styles.badge}>Выплата продавцу: {received} ₸</span>
            </div>

            <div className={styles.orderInfo}>
                <p><strong>Товар:</strong> {order.productName}</p>
                <p><strong>Логин:</strong> {order.login}</p>
                <p><strong>Телефон:</strong> {order.phone}</p>
                <p><strong>Город:</strong> {order.city}</p>
                <p><strong>Адрес:</strong> {order.address}</p>
                <p><strong>Сумма:</strong> {invoice} ₸ (Комиссия: {commission} ₸)</p>
            </div>

            <div className={styles.actions}>
                <button onClick={onReturn}>🔁 Оформить возврат</button>
            </div>

            <ChatButton userId={order.login} type="seller" />
        </div>
    );
};

export default OrderSuccessCard;