// src/constants/orderStatus.js
const ORDER_STATUS = {
    NONE: "none",
    REQUESTED: "requested",
    INVOICE_SENT: "invoice_sent",
    PAID: "paid",
    PAYMENT_DECLINED: "payment_declined",
    DELIVERY_PENDING: "delivery_pending",
    DELIVERY_ASSIGNED: "delivery_assigned",
    DELIVERY_ON_THE_WAY: "delivery_on_the_way",
    DELIVERED: "delivered",
    RETURN_ON_THE_WAY: "return_on_the_way",
    RETURNED: "returned",
    COMPLETED: "completed",
    CANCELLED: "cancelled"
};

export default ORDER_STATUS;