import React from 'react';
import styles from './OrderReturnCard.module.css';
import ChatButton from '../../components/ChatButton/ChatButton';

const OrderReturnCard = ({ order, onConfirm, openChatInNewTab }) => {
    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <h4>🔁 Запрошен возврат</h4>
                <span className={styles.badge}>Ожидает подтверждения</span>
            </div>

            <div className={styles.orderInfo}>
                <p><strong>Товар:</strong> {order.productName}</p>
                <p><strong>Логин:</strong> {order.login}</p>
                <p><strong>Телефон:</strong> {order.phone}</p>
                <p><strong>Город:</strong> {order.city}</p>
                <p><strong>Адрес:</strong> {order.address}</p>
                <p><strong>Сумма возврата:</strong> {order.invoice} ₸</p>
            </div>

            <div className={styles.note}>
                💬 Клиент инициировал возврат. Средства будут возвращены ему <strong>только после получения товара продавцом</strong>.
            </div>

            <div className={styles.buttonRow}>
                <div className={styles.actions}>
                    <button onClick={onConfirm}>✅ Подтвердить возврат</button>
                </div>

                <div className={styles.actions}>
                    <button onClick={onConfirm}>❌ Отклонить возврат</button>
                </div>

                <ChatButton userId={order.login} type="seller" />
            </div>

            <div className={styles.policy}>
                <p>📸 При отклонении возврата продавец обязан отправить фотоотчёты в чат с покупателем, подтверждающие повреждения товара, или отправить другой товар покупателю.</p>
                <p>⏱️ Если возврат товара осуществляется в течение 3 часов, деньги возвращаются покупателю.</p>
                <p>🕒 Если товар возвращается в течение 14 дней, расчёт возвратной суммы происходит вне платформы — напрямую между продавцом и покупателем.</p>
                <p>⚠️ Если возврат средств подтверждён техподдержкой, но одна из сторон (продавец или покупатель) нарушает договорённости, рейтинг нарушившей стороны понижается.</p>
            </div>
        </div>
    );
};

export default OrderReturnCard;