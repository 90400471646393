import React, { useState, useEffect, useRef } from "react";
import styles from './AdvertisementFolder.module.css';
import ProductCard from "./ProductCard";
import CartModal from "../AdvertisementFolder/CartModal";
import useCart from "../../hooks/useCart";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const categoriesData = {
    "Электроника": [
        "Бытовая техника",
        "Холодильники и морозильники",
        "Стиральные и сушильные машины",
        "Плиты, духовки, варочные панели",
        "Микроволновки",
        "Пылесосы",
        "Кондиционеры и обогреватели",
        "Водонагреватели",
        "Другая техника",
        "Компьютеры",
        "Ноутбуки",
        "ПК и комплектующие",
        "Мониторы",
        "Программное обеспечение",
        "Аксессуары",
        "Телефоны и аксессуары",
        "Смартфоны",
        "Кнопочные телефоны",
        "Планшеты",
        "Чехлы, защитные стекла",
        "Зарядные устройства, кабели",
        "Гарнитуры и наушники",
        "Оргтехника",
        "Принтеры и МФУ",
        "Сканеры",
        "Картриджи и тонеры",
        "Проекторы",
        "Другая техника",
    ],
    "Хозяйство и Дом": [
        "Мебель (диваны, шкафы, кровати, стулья и т.д.)",
        "Строительство и ремонт (инструменты, стройматериалы)",
        "Сад и огород (семена, саженцы, инструменты)",
        "Товары для дома (декор, посуда, текстиль)",
    ],
    "Промышленность и Бизнес": [
        "Оборудование (станки, генераторы)",
        "Сырье и материалы",
        "Торговое оборудование",
        "Готовый бизнес",
    ],
    "Авто и транспорт": [
        "Легковые авто",
        "Грузовики и спецтехника",
        "Мотоциклы и скутеры",
        "Запчасти и аксессуары",
    ],
    "Работа": ["Вакансии", "Резюме"],
    "Услуги": [
        "Ремонт и строительство",
        "Курьерские услуги",
        "Обучение и репетиторство",
        "Красота и здоровье",
    ],
    "Животные": [
        "Домашние животные (кошки, собаки, грызуны, птицы, рептилии)",
        "Сельскохозяйственные животные (коровы, лошади, овцы, козы, свиньи, птица)",
        "Рыбки и аквариумы",
        "Товары для животных (корм, аксессуары, вольеры, клетки)",
        "Вязка и услуги",
    ],
};

const Advertisements = () => {
    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("Электроника");
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [isOpen, setIsOpen] = useState(true); // Статус открытого / закрытого фильтра

    const {
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        totalPrice,
        currency
    } = useCart();

    useEffect(() => {
        fetchProducts();
    }, []);

    // UNUSED 
    const fetchProducts = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}:8085/api/v1/products/list`, {
                method: "GET",
                credentials: "include" // Для передачи куки
            });

            console.log("Response headers:", [...response.headers.entries()]);
            console.log("Response status:", response.status);

            if (!response.ok) throw new Error("Failed to fetch products");
            const data = await response.json();
            setProducts(data);
        } catch (err) {
            console.error("Fetch error:", err);
        }
    };
    const handleBuyProduct = async (productId) => {
        try {
            const token = localStorage.getItem("accessToken");
            if (!token) throw new Error("No access token found");

            const response = await fetch(`${API_BASE_URL}:8085/api/v1/products/purchase`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({ productId }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage);
            }

            alert("Product purchased successfully!");
            fetchProducts();
        } catch (err) {
            console.error(err);
            alert(`Could not purchase product: ${err.message}`);
        }
    };

    // Моковые данные (если API не отвечает)
    const mockData = [
        {
            id: 391941,
            name: "Smartphone X",
            city: "Astana",
            country: "Kazakhstan",
            description: "High-end smartphone with excellent camera and battery life.",
            price: "100",
            currency: "KZT",
            seller: "seller1",
            sellerType: "individual",
            paymentMethods: [
                { name: "Kaspi", installments: true },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: true }],
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-20",
            watch_count: 120
        },
        {
            id: 391942,
            name: "Gaming Laptop Z",
            city: "Almaty",
            country: "Kazakhstan",
            description: "Powerful gaming laptop with RTX 3080 GPU and 144Hz display.",
            price: "4500",
            currency: "KZT",
            seller: "techworld",
            sellerType: "legal",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: true },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: true }
            ],
            image: "https://img.kwcdn.com/product/open/1fe88cf488d04cf781b6867d82972103-goods.jpeg?imageView2/2/w/500/q/60/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/477f4de0098cafe0a88c72c963d0e12615589ae1.f30.mp4",
            published_at: "2024-03-19",
            watch_count: 305
        },
        {
            id: 391943,
            name: "Wireless Earbuds Pro",
            city: "Dubai",
            country: "UAE",
            description: "Noise-canceling wireless earbuds with immersive sound quality.",
            price: "200",
            currency: "USD",
            seller: "soundwave",
            sellerType: "individual",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ],
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-19",
            watch_count: 305
        },
        {
            id: 391944,
            name: "Smart Watch Ultra",
            city: "New York",
            country: "USA",
            description: "Smart watch with health tracking and 7-day battery life.",
            price: "350",
            currency: "USD",
            seller: "weartech",
            sellerType: "legal",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ], image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-18",
            watch_count: 87
        },
        {
            id: 391945,
            name: "4K Smart TV",
            city: "Tokyo",
            country: "Japan",
            description: "55-inch 4K HDR Smart TV with voice control.",
            price: "1200",
            currency: "JPY",
            seller: "techmart",
            sellerType: "legal",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ], image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-17",
            watch_count: 215
        },
        {
            id: 391946,
            name: "Portable Bluetooth Speaker",
            city: "Berlin",
            country: "Germany",
            description: "Compact and powerful Bluetooth speaker with deep bass.",
            price: "80",
            currency: "EUR",
            seller: "audiotech",
            sellerType: "individual",
            discount: 10,
            paymentMethods: [
                { name: "Kaspi", installments: false },
                { name: "Freedom", installments: false },
                { name: "Halyk", installments: false }
            ],            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-16",
            watch_count: 430
        },
        {
            id: 391947,
            name: "Electric Scooter",
            city: "Paris",
            country: "France",
            description: "Eco-friendly electric scooter with 25km range per charge.",
            price: "600",
            currency: "EUR",
            seller: "ecoride",
            sellerType: "individual",
            available: false,
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-15",
            watch_count: 152
        },
        {
            id: 391948,
            name: "Professional DSLR Camera",
            city: "London",
            country: "UK",
            description: "High-resolution DSLR camera with 4K video recording.",
            price: "2500",
            currency: "GBP",
            seller: "cameraworld",
            sellerType: "legal",
            available: true,
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            video: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4",
            published_at: "2024-03-15",
            watch_count: 152
        }
    ];
    const awaitingOrders = [
        {
            id: 1,
            name: "Наушники Beats",
            price: 199,
            quantity: 1,
            currency: "USD",
            city: "Almaty",
            country: "Kazakhstan",
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            address: "ул. Абая, 10"
        }
    ];
    const paidOrders = [
        {
            id: 2,
            name: "Игровая мышь",
            price: 59,
            quantity: 1,
            currency: "USD",
            city: "Astana",
            country: "Kazakhstan",
            image: "https://img.kwcdn.com/product/fancy/a32f6614-c416-4dbb-a2c3-7a3190a0ff29.jpg?imageView2/2/w/150/q/50/format/webp",
            address: "Самовывоз"
        }
    ];


    // Моя корзина 
    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const openCartModal = () => setIsCartModalOpen(true);
    const closeCartModal = () => setIsCartModalOpen(false);

    // Корзина - модалка

    return (
        <>
            <div className={styles.panel_width}>
                <div className={styles.filter_panel}>
                    <div className={styles.filter_panel_header}>
                        <div className={styles.filterHeaderLeft}>
                            <button className={styles.toggleButton} onClick={() => setIsOpen(prev => !prev)}>
                                {isOpen ? "⬆" : "⬇"}
                            </button>
                            <h3>Фильтры</h3>
                        </div>

                        <button
                            className={`${styles.cartButton} cart-float-button`}
                            onClick={openCartModal}
                        >
                            <strong>🛒 Моя корзина</strong>
                        </button>

                    </div>

                    {isOpen && (
                        <div className={styles.filter_panel_body}>
                            {/* Меню выбора категории */}
                            <p>Категория {selectedCategory ? `(Выбрано: ${selectedCategory})` : ""}</p>
                            <div className={styles.filter_panel_menu}>
                                {Object.keys(categoriesData).map((category) => (
                                    <div
                                        key={category}
                                        className={`${styles.category_item} ${selectedCategory === category ? styles.active : ""}`}
                                        onClick={() => {
                                            if (selectedCategory === category) {
                                                setSelectedCategory(null);
                                                setSelectedSubcategory(null);
                                            } else {
                                                setSelectedCategory(category);
                                                setSelectedSubcategory(null);
                                            }
                                        }}
                                    >
                                        {category}
                                    </div>
                                ))}
                            </div>

                            {/* Список подкатегорий */}
                            {selectedCategory && categoriesData[selectedCategory] && (
                                <>
                                    <p>Подкатегория {selectedSubcategory ? `(Выбрано: ${selectedSubcategory})` : ""}</p>
                                    <div className={styles.filter_panel_menuChoices}>
                                        {categoriesData[selectedCategory].map((subcategory, index) => (
                                            <div
                                                key={index}
                                                className={`${styles.panel_menChoices_shaffle} ${selectedSubcategory === subcategory ? styles.activeSubcategory : ""}`}
                                                onClick={() => {
                                                    setSelectedSubcategory(prev => prev === subcategory ? null : subcategory);
                                                }}
                                            >
                                                {subcategory}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}

                            {/* Кнопки управления фильтрами */}
                            <div className={styles.panel_buttons}>
                                <div className={styles.panel_button} onClick={() => alert(`Фильтры применены:\nКатегория: ${selectedCategory || "Не выбрано"}\nПодкатегория: ${selectedSubcategory || "Не выбрано"}`)}>
                                    Применить фильтры
                                </div>
                                <div className={styles.panel_button} onClick={() => {
                                    setSelectedCategory(null);
                                    setSelectedSubcategory(null);
                                }}>
                                    Стереть фильтры
                                </div>
                            </div>
                        </div>
                    )}

                    <CartModal
                        isOpen={isCartModalOpen}
                        onClose={closeCartModal}
                        title="🛒 Моя корзина"
                        cartItems={cartItems}
                        removeFromCart={removeFromCart}
                        clearCart={clearCart}
                        totalPrice={totalPrice}
                        currency={currency}
                        awaitingOrders={awaitingOrders}
                        paidOrders={paidOrders}
                    >
                    </CartModal>
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.product_container}>
                    <div className={styles.product_search}>
                        <input className={styles.product_searchInput} placeholder="Поиск"></input>
                        <button className={styles.product_searchButton}>🔎</button>
                    </div>

                    <div className={styles.productsList}>
                        {mockData.map((product) => (
                            <ProductCard
                                key={product.id}
                                product={product}
                                handleBuyProduct={handleBuyProduct}
                                addToCart={addToCart}
                            />
                        ))}
                    </div>
                </div>

                <div className={styles.sideBar}>
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                    <br />
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                    Some content with border lines.
                    <br />
                </div>
            </div>
        </>
    )
};

export default Advertisements;