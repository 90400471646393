import React, { useState } from 'react';
import styles from './OrderInvoiceCard.module.css';
import ChatButton from '../../components/ChatButton/ChatButton';

import kaspiLogo from "../../assets/images/Payment/kaspi.png";
import halykLogo from "../../assets/images/Payment/halyk.png";
import freedomLogo from "../../assets/images/Payment/freedom.png";

import { notify } from "../../components/NotificationContainer/NotificationContainer";

const OrderInvoiceCard = ({ order, onSetInvoice }) => {
    const [invoiceInput, setInvoiceInput] = useState('');
    const [selectedBanks, setSelectedBanks] = useState([]);
    const isAddressMissing = order.address.trim() === "Самовывоз";
    const [paymentMethod, setPaymentMethod] = useState("full"); // "full" | "installment"

    const BANK_OPTIONS = [
        { id: "kaspi", label: "Kaspi", logo: kaspiLogo },
        { id: "halyk", label: "Halyk Bank", logo: halykLogo },
        { id: "freedom", label: "Freedom Bank", logo: freedomLogo },
    ];

    const handleSubmit = () => {
        if (!invoiceInput || isNaN(invoiceInput)) {
            notify("❗ Введите корректную сумму", 4000);
            return;
        }

        if (selectedBanks.length === 0) {
            notify("❗ Выберите хотя бы один банк для получения оплаты", 4000);
            return;
        }

        notify("✅ Счёт успешно выставлен", 4000);
        onSetInvoice(invoiceInput); // или передай selectedBanks, если нужно
    };

    const handleBankToggle = (bank) => {
        setSelectedBanks((prev) =>
            prev.includes(bank) ? prev.filter(b => b !== bank) : [...prev, bank]
        );
    };

    return (
        <div className={styles.card}>
            <div className={styles.orderInfo}>
                <p><strong>Логин:</strong> {order.login}</p>
                <p><strong>Телефон:</strong> {order.phone}</p>
                <p><strong>Город:</strong> {order.city}</p>
                <p><strong>Адрес:</strong> {order.address}</p>
                <p><strong>Количество:</strong> {order.count}</p>
                <p><strong>Сумма товара:</strong> {order.amount} {order.currency}</p>
            </div>

            {isAddressMissing && (
                <p className={styles.disabledNote}>
                    ❗ Адрес доставки отсутствует. Выставить счёт невозможно.
                </p>
            )}

            <div className={styles.invoiceInput}>
                <input
                    type="number"
                    value={invoiceInput}
                    placeholder="Цена за доставку"
                    onChange={(e) => setInvoiceInput(e.target.value)}
                    disabled={isAddressMissing}
                />

                <div className={styles.bankSelector}>
                    <p className={styles.bankLabel}>Куда принять оплату:</p>
                    <div className={styles.bankOptions}>
                        {BANK_OPTIONS.map((bank) => (
                            <label key={bank.id} className={styles.bankOption}>
                                <input
                                    type="checkbox"
                                    value={bank.id}
                                    checked={selectedBanks.includes(bank.id)}
                                    onChange={() => handleBankToggle(bank.id)}
                                    disabled={isAddressMissing}
                                />
                                <img src={bank.logo} alt={bank.label} />
                            </label>
                        ))}
                    </div>
                </div>

                <div className={styles.paymentMethod}>
                    <label className={styles.paymentCheckbox}>
                        <input
                            type="checkbox"
                            checked={paymentMethod === "installment"}
                            onChange={(e) => {
                                setPaymentMethod(e.target.checked ? "installment" : "full");
                            }}
                            disabled={isAddressMissing}
                        />
                        Оплата клиента возможна в рассрочку через банк
                    </label>
                </div>

                <button
                    className={styles.submitButton}
                    onClick={handleSubmit}
                    disabled={isAddressMissing}
                >
                    Выставить счёт
                </button>

                <div className={styles.commissionWrapper}>
                    <label>Комиссия (3%):</label>
                    <input
                        type="number"
                        readOnly
                        value={Math.round((+order.amount + +invoiceInput) * 0.03) || 0}
                        className={styles.commissionInput}
                    />
                </div>

                <div className={styles.commissionWrapper}>
                    <label><strong>Итого к оплате:</strong></label>
                    <input
                        type="number"
                        readOnly
                        value={
                            (+order.amount || 0) +
                            (+invoiceInput || 0) +
                            Math.round((+order.amount + +invoiceInput) * 0.05)
                        }
                        className={styles.commissionInput}
                    />
                </div>
            </div>

            <p className={styles.note}>
                💬 Покупатель оформил заявку на товар. Продавец должен <strong>указать цену доставки</strong>, после чего будет рассчитана комиссия и общая сумма.<br />
                Нажмите «Выставить счёт», чтобы передать заявку в раздел <strong>«Ожидается оплата»</strong>.
            </p>

            <ChatButton userId={order.login} type="seller" />
        </div>
    );
};

export default OrderInvoiceCard;