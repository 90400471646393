import React, { useEffect, useState } from "react";
import styles from "./EmailChangeModal.module.css";
import { notify } from "../../components/NotificationContainer/NotificationContainer";

const EmailChangeModal = ({
    mode = "email",
    currentEmail,
    onClose,
    onEmailChange,
    onSuccess // для смены пароля
}) => {
    const [step, setStep] = useState("verifyOld");
    const [oldEmailVerified, setOldEmailVerified] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [code, setCode] = useState(["", "", "", ""]);
    const [newEmailSent, setNewEmailSent] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [emailSentTo, setEmailSentTo] = useState(""); // адрес, на который был отправлен код

    // Таймер для текущей почты
    const [oldCanResend, setOldCanResend] = useState(true);
    const [oldResendTimer, setOldResendTimer] = useState(30);
    const [oldProgress, setOldProgress] = useState(100);

    // Таймер для новой почты
    const [newCanResend, setNewCanResend] = useState(true);
    const [newResendTimer, setNewResendTimer] = useState(30);
    const [newProgress, setNewProgress] = useState(100);


    // Таймер для текущей почты
    useEffect(() => {
        let timer;
        if (!oldCanResend && oldResendTimer > 0) {
            timer = setTimeout(() => {
                setOldResendTimer((prev) => prev - 1);
                setOldProgress((prev) => prev - (100 / 30));
            }, 1000);
        } else if (oldResendTimer === 0) {
            setOldCanResend(true);
            setOldResendTimer(30);
            setOldProgress(100);
        }
        return () => clearTimeout(timer);
    }, [oldCanResend, oldResendTimer]);

    // Таймер для новой почты
    useEffect(() => {
        let timer;
        if (!newCanResend && newResendTimer > 0) {
            timer = setTimeout(() => {
                setNewResendTimer((prev) => prev - 1);
                setNewProgress((prev) => prev - (100 / 30));
            }, 1000);
        } else if (newResendTimer === 0) {
            setNewCanResend(true);
            setNewResendTimer(30);
            setNewProgress(100);
        }
        return () => clearTimeout(timer);
    }, [newCanResend, newResendTimer]);

    const handleCodeInput = (index, value) => {
        const updated = [...code];
        updated[index] = value.replace(/[^0-9]/g, "");
        setCode(updated);
    };

    const handleVerifyOld = () => {
        const finalCode = code.join("");
        if (finalCode.length === 4) {
            setOldEmailVerified(true);
            setCode(["", "", "", ""]);

            if (mode === "email") {
                setStep("enterNew");
                notify("📨 Почта подтверждена", 4000);
            } else {
                notify("✅ Подтверждено", 4000);
                onSuccess?.(); // вызов callback, если смена пароля
            }
        }
    };

    const handleSendCodeToNewEmail = () => {
        if (!newEmail.includes("@")) {
            notify("⚠️ Введите корректный email", 4000);
            return;
        }

        if (newEmail !== emailSentTo || !newEmailSent) {
            notify(`📨 Код отправлен на ${newEmail}`, 4000);
            setEmailSentTo(newEmail);
            setNewEmailSent(true);
            setNewCanResend(false);
            setNewResendTimer(30);
            setNewProgress(100);
        }

        setStep("verifyNew");
    };

    const handleVerifyNew = () => {
        const finalCode = code.join("");
        if (finalCode.length === 4) {
            onEmailChange(newEmail);
            notify("\u2705 Email успешно изменён", 4000);
            onClose();
        } else {
            notify("\u274C Введите полный код", 4000);
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                {step === "verifyOld" && !oldEmailVerified && (
                    <>
                        <h3>
                            {mode === "email"
                                ? "Подтвердите вашу текущую почту"
                                : "Подтвердите вашу личность"}
                        </h3>

                        {mode === "email" ? (
                            <p className={styles.fieldHint}>
                                Прежде чем вы сможете сменить email, необходимо подтвердить, что вы действительно владеете текущим адресом <strong>{currentEmail}</strong>.
                            </p>
                        ) : (
                            <p className={styles.fieldHint}>
                                Перед выполнением этого действия необходимо подтвердить, что вы владелец аккаунта. Мы отправим код на вашу почту.
                            </p>
                        )}

                        {!codeSent ? (
                            <button
                                className={styles.submitBtn}
                                onClick={() => {
                                    setCodeSent(true);
                                    setOldCanResend(false);
                                    notify(`📨 Код отправлен на ${currentEmail}`, 4000);
                                }}
                                disabled={!oldCanResend}
                            >
                                {oldCanResend ? "Отправить код" : `Повторно через ${oldResendTimer}s`}
                                {!oldCanResend && (
                                    <div className={styles.progressBar}>
                                        <div className={styles.progressInner} style={{ width: `${oldProgress}%` }}></div>
                                    </div>
                                )}
                            </button>
                        ) : (
                            <>
                                <p className={styles.fieldHint}>
                                    Мы отправили на него 4-значный код. Введите его ниже, чтобы продолжить.
                                </p>
                                <div className={styles.codeInputs}>
                                    {code.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) => handleCodeInput(index, e.target.value)}
                                            className={styles.codeInput}
                                        />
                                    ))}
                                </div>
                                <button className={styles.submitBtn} onClick={handleVerifyOld}>
                                    Подтвердить
                                </button>
                            </>
                        )}
                    </>
                )}

                {step === "enterNew" && (
                    <>
                        <h3>Введите новый email</h3>
                        <p className={styles.fieldHint}>
                            После подтверждения текущей почты вы можете указать новый адрес, на который будет отправлен код подтверждения. Только после ввода кода ваш email будет обновлён.
                        </p>
                        <input
                            className={styles.modalInput}
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            placeholder="example@newmail.com"
                        />
                        <div className={styles.actionsRow}>
                            <button
                                className={styles.submitBtn}
                                onClick={handleSendCodeToNewEmail}
                                disabled={!newCanResend}
                            >
                                {newCanResend ? "Отправить код" : `Повторно через ${newResendTimer}s`}
                                {!newCanResend && (
                                    <div className={styles.progressBar}>
                                        <div className={styles.progressInner} style={{ width: `${newProgress}%` }}></div>
                                    </div>
                                )}
                            </button>
                        </div>
                    </>
                )}

                {step === "verifyNew" && (
                    <>
                        <h3>Подтверждение нового email</h3>
                        <p className={styles.fieldHint}>
                            Введите код, отправленный на <strong>{newEmail}</strong>
                        </p>
                        <div className={styles.codeInputs}>
                            {code.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleCodeInput(index, e.target.value)}
                                    className={styles.codeInput}
                                />
                            ))}
                        </div>
                        <div className={styles.actionsRow}>
                            <button className={styles.clearBtn} onClick={() => setStep("enterNew")}>← Назад</button>
                            <button className={styles.submitBtn} onClick={handleVerifyNew}>Подтвердить</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EmailChangeModal;
