// ProductCard.jsx
import React, { useState, useRef } from "react";
import styles from './ProductCard.module.css';
import ProductModal from "./ProductModal";

const ProductCard = ({ product, handleBuyProduct, addToCart }) => {
    // Картинка/Видео
    const videoRef = useRef(null);
    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play().catch(error => console.log("Autoplay error:", error));
        }
    };
    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    // Модалька
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleInfoProduct = () => {
        setIsModalOpen(true); // открыть модалку
    };
    const closeModal = () => setIsModalOpen(false);

    // Слайдер
    product.media = [
        { type: "image", src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5CiYemU3YKQhAZ-j4AUWb8-mFCyGD2TOeFg&s" },
        { type: "video", src: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4" },
        { type: "image", src: "https://www.spot.uz/media/img/2023/10/cN7ajJ16965926451306_b.jpg" }
    ];

    return (
        <>
            <div
                className={styles.productCard}
                onClick={() => handleInfoProduct(product.id)}
            >
                {/* Блок с картинкой и видео */}
                <div
                    className={styles.one}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ backgroundImage: `url(${product.image})` }}
                >
                    <video ref={videoRef} src={product.video} muted loop />
                </div>

                {/* Название + Цена справа */}
                <div className={styles.titleRow}>
                    <a className={styles.productName}>{product.name}</a>
                    <div className={styles.priceBlock}>
                        <span className={styles.price}>{product.price}</span>
                        <span className={styles.currency}>{product.currency}</span>
                    </div>
                </div>


                {/* Описание */}
                <div className={styles.six}>{product.description}</div>

                {/* Блок: Цена + Банки + Рассрочка */}
                <div className={styles.pricingSection}>
                    {/* Цена и банки */}
                    <div className={styles.three}>
                        <div className={styles.bankBadges}>
                            {(product.paymentMethods || []).map(({ name }) => (
                                <span key={name} className={`${styles.bankMini} ${styles[name.toLowerCase()]}`}>
                                    {name}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Рассрочка */}
                    {product.paymentMethods?.filter(p => p.installments).length > 0 ? (
                        <div className={styles.four}>
                            <div className={styles.installmentsTitle}>Рассрочка доступна в:</div>
                            <div className={styles.installmentsList}>
                                {product.paymentMethods
                                    .filter(p => p.installments)
                                    .map(({ name }) => (
                                        <span key={name} className={styles.installment}>{name}</span>
                                    ))}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.four_none}></div>
                    )}

                    {/* Контейнер для просмотров и даты */}
                    <div className={styles.footer_card}>
                        <div className={styles.footer_card_bottom}>
                            <div className={styles.product_footer}>
                                <div className={styles.watchCount}> 👁 {product.watch_count}</div>
                                <div className={styles.publishedAt}>{product.published_at}</div>
                            </div>
                        </div>
                    </div>

                    {/* Скидки от амбассадора */}
                    <div
                        className={
                            product.sellerType === "legal" && product.discount
                                ? styles.legalDiscount
                                : styles.legalDiscountUnavailable
                        }
                    >
                        {product.sellerType === "legal" && product.discount ? (
                            <>
                                Промокод для скидки от амбассадоров: <strong>{product.discount}%</strong>
                            </>
                        ) : (
                            <>Промокод недоступен</>
                        )}
                    </div>

                    {/* Город, страна, продавец */}
                    <div className={styles.five}>
                        <div className={styles.sellerTypeLine}>
                            {product.sellerType === "legal" ? "🏢 Юридическое лицо" : "🧑 Физическое лицо"} — <strong>{product.seller}</strong>
                        </div>
                        <div>{product.city}, {product.country}</div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <ProductModal
                    product={product}
                    onClose={closeModal}
                    addToCart={addToCart}
                />
            )}
        </>
    );
};

export default ProductCard;