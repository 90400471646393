import React, { useEffect, useState } from "react";
import styles from "./LegalConfiguration.module.css";
import COUNTRIES from "../../data/countriesData";
import citiesData from "../../data/citiesData";
import EmailChangeModal from "./EmailChangeModal";
import ChangePasswordModal from "./ChangePasswordModal";

import ChatButton from '../../components/ChatButton/ChatButton';

import instagramLogo from "../../assets/svg/Instagram_logo.svg";
import facebookLogo from "../../assets/svg/Facebook_Logo.svg";
import twitterLogo from "../../assets/svg/twitter-logo.svg";

const LegalConfiguration = () => {
    const [ambassadorPhoto, setAmbassadorPhoto] = useState(null);
    const [faceFileName, setFaceFileName] = useState("");
    const [documentFileName, setDocumentFileName] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(() => { // 🔐
        const token = localStorage.getItem("accessToken");
        return Boolean(token);
    });
    const [isVerified, setIsVerified] = useState(false); // 🟢 по умолчанию не верифицирован
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");

    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

    // Модальное окно 
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const draft = {
            phone,
            country,
            city,
            ambassadorPhoto
        };
        localStorage.setItem("userProfileDraft", JSON.stringify(draft));
    }, [phone, country, city, ambassadorPhoto]);

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result;
                setAmbassadorPhoto(base64);

                const updatedDraft = {
                    phone,
                    country,
                    city,
                    ambassadorPhoto: base64
                };
                localStorage.setItem("userProfileDraft", JSON.stringify(updatedDraft));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = () => {
        const data = {
            phone,
            country,
            city,
            ambassadorPhoto
        };
        localStorage.setItem("userProfileDraft", JSON.stringify(data));
        alert("Данные сохранены в localStorage");
    };

    return (
        <>
            <div className={styles.wrapper}>
                <h2 className={styles.header}>Конфигурация юридического лица</h2>

                <p className={styles.pageDescription}>
                    Здесь вы можете указать основные данные для работы с платформой. Часть информации доступна для редактирования без входа в аккаунт. Чтобы изменить защищённые поля, необходимо авторизоваться.
                </p>

                <div className={styles.sectionsRow}>
                    {/* Левая колонка — без аутентификации */}
                    <section className={styles.sectionLeft}>
                        <h3 className={styles.subheading}>Изменения без аутентификации</h3>
                        <div className={styles.fieldGroup}>
                            <label>Страна</label>
                            <select value={country} onChange={(e) => {
                                setCountry(e.target.value);
                                setCity(""); // сбрасываем город при смене страны
                            }}>
                                <option value="">Выберите страну</option>
                                {COUNTRIES.map(c => (
                                    <option key={c.value} value={c.value}>{c.label}</option>
                                ))}
                            </select>
                        </div>
                        {country && (
                            <div className={styles.fieldGroup}>
                                <label>Город</label>
                                <select value={city} onChange={(e) => setCity(e.target.value)}>
                                    <option value="">Выберите город</option>
                                    {citiesData[country]?.map(c => (
                                        <option key={c.value} value={c.value}>{c.label}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className={styles.fieldGroup}>
                            <label>Логотип компании (1:1, jpg/png)</label>

                            <div className={styles.fileUploadWrapper}>
                                <label htmlFor="ambassadorUpload" className={styles.fileBtn}>
                                    📎 Загрузить фото
                                </label>
                                <input
                                    id="ambassadorUpload"
                                    type="file"
                                    accept="image/*"
                                    className={styles.fileInput}
                                    onChange={handlePhotoChange}
                                />
                                <span className={styles.fileName}>
                                    {ambassadorPhoto ? "Файл выбран ✅" : "Файл не выбран"}
                                </span>
                            </div>

                            {ambassadorPhoto && (
                                <>
                                    <img src={ambassadorPhoto} alt="Амбассадор" className={styles.photoPreview} />
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => {
                                            setAmbassadorPhoto(null);
                                            const saved = localStorage.getItem("userProfileDraft");
                                            if (saved) {
                                                const parsed = JSON.parse(saved);
                                                delete parsed.ambassadorPhoto;
                                                localStorage.setItem("userProfileDraft", JSON.stringify(parsed));
                                            }
                                        }}
                                    >
                                        Удалить фото
                                    </button>
                                </>
                            )}
                        </div>

                        <button className={styles.clearBtn} onClick={() => {
                            localStorage.removeItem("userProfileDraft");
                            setPhone("");
                            setCountry("");
                            setCity("");
                            setAmbassadorPhoto(null);
                            alert("Данные успешно удалены.");
                        }}>
                            Очистить
                        </button>

                        <section className={`${styles.sectionLeftBottom} ${styles.authSectionLeft}`}>
                            <p className={styles.supportTitle}>Техническая поддержка:</p>
                            <ChatButton />

                            <div className={styles.supportBlock}>
                                <div className={styles.socialLinks}>
                                    <a href="https://instagram.com/your_company" target="_blank" rel="noopener noreferrer">
                                        <img src={instagramLogo} alt="Instagram" />
                                    </a>
                                    <a href="https://facebook.com/your_company" target="_blank" rel="noopener noreferrer">
                                        <img src={facebookLogo} alt="Facebook" />
                                    </a>
                                    <a href="https://twitter.com/your_company" target="_blank" rel="noopener noreferrer">
                                        <img src={twitterLogo} alt="Twitter" />
                                    </a>
                                </div>
                            </div>
                        </section>
                    </section>




                    {/* Правая колонка — с аутентификацией */}
                    <section className={`${styles.sectionRight} ${styles.authSection}`}>
                        <h3 className={styles.subheading}>{isVerified ? "Верифицирован" : "Не верифицирован"}</h3>

                        <div className={styles.fieldGroup}>
                            <label>Почта</label>
                            <div className={styles.mailRow}>
                                <input
                                    className={styles.mailInput}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    readOnly={!isAuthenticated}
                                    placeholder={!isAuthenticated ? "example@email.com" : ""}
                                />
                                <button className={styles.mailBtn} onClick={() => setIsModalOpen(true)}>
                                    Поменять
                                </button>
                            </div>
                        </div>

                        <div className={styles.fieldGroup}>
                            <label>Пароль</label>
                            <button
                                className={styles.passwordBtn}
                                onClick={() => setIsPasswordModalOpen(true)}
                            >
                                Изменить пароль
                            </button>
                        </div>

                        <div className={styles.fieldGroup}>
                            <label>Наименование компании</label>
                            <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                readOnly={!isAuthenticated}
                            />
                        </div>

                        <div className={styles.fieldGroup}>
                            <label>Описание компании</label>
                            <input
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                readOnly={!isAuthenticated}
                            />
                        </div>

                        <div className={styles.fieldGroup}>
                            <label>Номер телефона</label>
                            <input value={phone} readOnly disabled />
                        </div>

                        <div className={styles.fieldGroup}>
                            <label>ИИН/БИН</label>
                            <input
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                readOnly={!isAuthenticated}
                            />
                        </div>


                        <div className={styles.fieldGroup}>
                            <label>Дата регистрации</label>
                            <input
                                type="date"
                                value={birthdate}
                                onChange={(e) => setBirthdate(e.target.value)}
                                readOnly={!isAuthenticated}
                            />
                        </div>



                        <p className={`${styles.fieldHint} ${styles.important}`}>
                            Для процедуры фотографирования, можете использовать мобильный телефон.
                        </p>

                        <div className={styles.fieldGroup}>
                            <label>Талон или свидетельство о государственной регистрации компании</label>
                            <div className={styles.fileUploadWrapper}>
                                <label htmlFor="docUpload" className={styles.fileBtn}>
                                    📷 Фотографировать
                                </label>
                                <input
                                    id="docUpload"
                                    type="file"
                                    accept="image/*"
                                    className={styles.fileInput}
                                    capture="environment"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setDocumentFileName(file.name);
                                            alert("✅ Фото успешно прикреплено");
                                        }
                                    }}
                                />
                                <span className={styles.fileName}>
                                    {documentFileName || "Файл не выбран"}
                                </span>
                            </div>
                            <p className={styles.fieldHint}>
                                Сфотографируйте <strong>талон</strong> или <strong>свидетельство о государственной регистрации</strong> вашей компании. <br />
                                Будет использована <strong>задняя камера</strong> устройства (если поддерживается).
                            </p>
                        </div>

                        <div className={styles.actionsRow}>
                            {isVerified ? (
                                <button className={styles.mailBtn}>Подать на изменения данных</button>
                            ) : (
                                <button className={styles.submitBtn}>Пройти верификацию</button>
                            )}
                        </div>


                        {/* 🔐 Оверлей сверху, если не авторизован */}
                        {!isAuthenticated && (
                            <div className={styles.lockedOverlay}>
                                <div className={styles.lockedIcon}>🔑</div>
                                <button
                                    className={styles.authBtn}
                                    onClick={() => alert("Переход к авторизации...")}
                                >
                                    Войти / Зарегистрироваться
                                </button>
                            </div>
                        )}
                    </section>
                </div>
            </div>

            {isModalOpen && (
                <EmailChangeModal
                    mode="email" // или "password"
                    currentEmail={email}
                    onClose={() => setIsModalOpen(false)}
                    onEmailChange={(newEmail) => setEmail(newEmail)}
                />
            )}

            {isPasswordModalOpen && (
                <EmailChangeModal
                    mode="password"
                    currentEmail={email}
                    onClose={() => setIsPasswordModalOpen(false)}
                    onSuccess={() => {
                        setIsPasswordModalOpen(false);
                        setIsChangePasswordOpen(true);
                    }}
                />
            )}

            {isChangePasswordOpen && (
                <ChangePasswordModal
                    onClose={() => setIsChangePasswordOpen(false)}
                />
            )}

        </>
    );
};

export default LegalConfiguration;