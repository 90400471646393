import React, { useState } from "react";
import styles from "./ChangePasswordModal.module.css";
import { notify } from "../../components/NotificationContainer/NotificationContainer";

const ChangePasswordModal = ({ onClose }) => {
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    const handleChange = () => {
        if (newPassword.length < 6) {
            notify("\u26A0\uFE0F Пароль должен быть не менее 6 символов", 4000);
            return;
        }
        if (newPassword !== repeatPassword) {
            notify("\u274C Пароли не совпадают", 4000);
            return;
        }

        // Здесь может быть реальный API-запрос на смену пароля
        notify("\u2705 Пароль успешно изменён", 4000);
        onClose();
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h3 className={styles.subheading}>Смена пароля</h3>
                <div className={styles.fieldGroup}>
                    <label>Новый пароль</label>
                    <div className={styles.passwordWrapper}>
                        <input
                            type={showNewPassword ? "text" : "password"}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={styles.input}
                            placeholder="Введите новый пароль"
                        />
                        <button
                            type="button"
                            className={styles.eyeBtn}
                            onClick={() => setShowNewPassword((prev) => !prev)}
                        >
                            <div className={styles.eyeIcon}>
                                {showNewPassword ? (
                                    <span className={styles.eyeLine}>👁</span>
                                ) : (
                                    <span>👁</span>
                                )}
                            </div>
                        </button>
                    </div>
                </div>
                <div className={styles.fieldGroup}>
                    <label>Повторите пароль</label>
                    <div className={styles.passwordWrapper}>
                        <input
                            type={showRepeatPassword ? "text" : "password"}
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            className={styles.input}
                            placeholder="Повторите новый пароль"
                        />
                        <button
                            type="button"
                            className={styles.eyeBtn}
                            onClick={() => setShowRepeatPassword(prev => !prev)} // ✅ правильный state
                        >
                            <div className={styles.eyeIcon}>
                                {showRepeatPassword ? (
                                    <span className={styles.eyeLine}>👁</span>
                                ) : (
                                    <span>👁</span>
                                )}
                            </div>
                        </button>
                    </div>
                </div>
                <button className={styles.submitBtn} onClick={handleChange}>
                    Сменить пароль
                </button>
            </div>
        </div>
    );
};

export default ChangePasswordModal;