import { useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import adServletLogo from "../../assets/svg/ad_servlet_logo.svg";

import bellIcon from "../../assets/svg/bell-860.png";

export default function Navbar() {
    const [role, setRole] = useState(null);
    const navigate = useNavigate();
    const [notificationOpen, setNotificationOpen] = useState(false);
    const bellRef = useRef(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const logoRef = useRef(null);
    const [logoStyles, setLogoStyles] = useState({});

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    const NavLink = ({ to, children }) => (
        <Link to={to} onClick={() => { setMenuOpen(false); }}>
            {children}
        </Link>
    );

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setRole(decoded.role);

                const path = window.location.pathname;
                if (decoded.role === "ROLE_LEGAL" && !path.startsWith("/legal")) {
                    navigate("/legal-dashboard");
                }
            } catch (err) {
                console.error("Token decoding failed:", err);
            }
        }
    }, [navigate]);

    useEffect(() => {
        const updatePositions = () => {
            const logo = logoRef.current;
            const footer = document.getElementById("page-footer"); // Ищем футер в DOM

            if (!logo || !footer) return;

            const logoRect = logo.getBoundingClientRect();
            const footerRect = footer.getBoundingClientRect();

            return {
                logoInitialTop: logoRect.top + window.pageYOffset,
                logoHeight: logo.offsetHeight,
                footerTop: footerRect.top + window.pageYOffset,
            };
        };

        let { logoInitialTop, logoHeight, footerTop } = updatePositions() || {};

        const handleScroll = () => {
            if (!logoInitialTop || !logoHeight || !footerTop) return;

            const scrollY = window.pageYOffset;

            if (scrollY >= logoInitialTop && scrollY + logoHeight < footerTop) {
                setLogoStyles({ position: "fixed", top: "0px" });
            } else if (scrollY + logoHeight >= footerTop) {
                setLogoStyles({ position: "absolute", top: `${footerTop - logoHeight}px` });
            } else {
                setLogoStyles({ position: "static" });
            }
        };

        const observer = new MutationObserver(() => {
            const positions = updatePositions();
            if (positions) {
                logoInitialTop = positions.logoInitialTop;
                logoHeight = positions.logoHeight;
                footerTop = positions.footerTop;
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", () => {
            const positions = updatePositions();
            if (positions) {
                logoInitialTop = positions.logoInitialTop;
                logoHeight = positions.logoHeight;
                footerTop = positions.footerTop;
            }
        });

        handleScroll();

        return () => {
            observer.disconnect();
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", updatePositions);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bellRef.current && !bellRef.current.contains(event.target)) {
                setNotificationOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div>
            <div className={styles["navbar-contain"]}>
                <div className={`${styles.burger} ${menuOpen ? styles.open : ""}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <header className={`${styles["navbar-header"]} ${menuOpen ? styles.open : ""}`}>
                    <nav>
                        <ul className={styles["navbar-mainUl"]}>
                            <li>
                                <Link to="/advertisements">Маркет</Link>
                                <ul>
                                    <li><NavLink to="/learning">Обучение</NavLink></li>
                                    <li><NavLink to="/apply">Подать</NavLink></li>
                                    <li><NavLink to="/courier">Курьер</NavLink></li>
                                    <li><NavLink to="/chitchat">Чаты</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="#">Продукты</Link>
                                <ul>
                                    <li><NavLink to="/mailing">Рассылки</NavLink></li>
                                    <li><NavLink to="/ai_chatbot">ИИ чат бот</NavLink></li>
                                    <li><NavLink to="/ai_caller">ИИ бот прозвонов</NavLink></li>
                                    <li><NavLink to="/detection">Детекция WIFI</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                {role === "ROLE_ANONYMOUS" && (
                                    <Link to="/cashback">Кешбэки</Link>
                                )}

                                {role === "ROLE_LEGAL" && (
                                    <Link to="/cashback">Мой бизнес</Link>
                                )}
                                <ul>
                                    <li><NavLink to="/analytics">Аналитика продаж</NavLink></li>
                                    {role === "ROLE_ANONYMOUS" && (
                                        <li><NavLink to="/dynamics">Динамика продаж</NavLink></li>
                                    )}

                                    {role === "ROLE_LEGAL" && (
                                        <li><NavLink to="/ambassadors">Амбассадоры</NavLink></li>
                                    )}
                                    <li><NavLink to="/partners">Юридические партнёры</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="/balance">Счёт</NavLink>
                            </li>
                            <li>
                                {role === "ROLE_ANONYMOUS" && (
                                    <NavLink to="/configuration">Конфигурация</NavLink>
                                )}

                                {role === "ROLE_LEGAL" && (
                                    <NavLink to="/legalconfiguration">Конфигурация</NavLink>
                                )}

                            </li>
                        </ul>
                    </nav>

                    <div className={styles["navbar-bell"]} ref={bellRef} onClick={() => setNotificationOpen(!notificationOpen)}>
                        <img src={bellIcon} alt="Notifications" />
                        <span className={styles["notification-count"]}>3</span> {/* 👈 бейдж */}

                        {notificationOpen && (
                            <div className={styles["navbar-dropdown"]}>
                                <div className={styles["dropdown-header"]}>
                                    <span>Уведомления</span>
                                    <button onClick={() => alert("Очищено!")}>Очистить всё</button>
                                </div>

                                <div className={styles["dropdown-content"]}>
                                    <div className={styles["notification-icon"]}>💬</div>
                                    <div className={styles["notification-item"]}>
                                        <div className={styles["notification-text"]}>
                                            <strong>Новое сообщение</strong>
                                            <p>У вас новое сообщение от службы поддержки.</p>
                                        </div>
                                        <span className={styles["notification-time"]}>2 мин назад</span>
                                    </div>

                                    <div className={styles["notification-icon"]}>💬</div>
                                    <div className={styles["notification-item"]}>
                                        <div className={styles["notification-text"]}>
                                            <strong>Заказ отправлен</strong>
                                            <p>Ваш заказ #123456 был передан курьеру.</p>
                                        </div>
                                        <span className={styles["notification-time"]}>30 мин назад</span>
                                    </div>

                                    <div className={styles["notification-icon"]}>💬</div>
                                    <div className={styles["notification-item"]}>
                                        <div className={styles["notification-text"]}>
                                            <strong>Обновление системы</strong>
                                            <p>Система будет недоступна 12 апреля с 00:00 до 02:00.</p>
                                        </div>
                                        <span className={styles["notification-time"]}>1 час назад</span>
                                    </div>
                                </div>

                                <div className={styles["dropdown-footer"]}>
                                    <button onClick={() => navigate("/notifications")}>Показать все</button>
                                </div>
                            </div>
                        )}

                    </div>
                </header>

                <div className={styles["navbar-logoContainer"]}>
                    <img
                        src={adServletLogo}
                        alt="Ad Servlet Logo"
                        ref={logoRef}
                        className={styles["navbar-logo"]}
                        style={{ ...logoStyles, left: "initial", right: "initial" }}
                    />
                </div>
            </div>
        </div>
    );
}