import React, { useState } from 'react';
import styles from './ChatSidebar.module.css';

const dummyUsers = [
    { id: 1, name: 'user_alpha', role: 'client', status: 'Ожидает оплаты', hasNewMessages: true },
    { id: 2, name: 'client_beta', role: 'support', status: 'У курьера', hasNewMessages: false },
    { id: 3, name: 'buyer_gamma', role: 'seller', status: 'Сделка удалась', hasNewMessages: true },
];

const ChatSidebar = ({ onSelectChat, selectedChat }) => {
    const [search, setSearch] = useState('');

    const filteredUsers = dummyUsers.filter(user =>
        user.name.toLowerCase().includes(search.toLowerCase())
    );

    const getRoleLabel = (role) => {
        switch (role) {
            case 'client':
                return 'Клиент';
            case 'seller':
                return 'Продавец';
            case 'support':
                return 'Поддержка';
            default:
                return '';
        }
    };

    return (
        <div className={styles.sidebar}>
            <h3>🧾 Заявки</h3>

            <input
                type="text"
                placeholder="Поиск..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={styles.searchInput}
            />

            <ul>
                {filteredUsers.map((user) => (
                    <li
                        key={user.id}
                        onClick={() => onSelectChat(user)}
                        className={`${styles.chatItem} ${selectedChat?.id === user.id ? styles.active : ''}`}
                    >
                        <div className={styles.avatar}>
                            {user.name.charAt(0).toUpperCase()}
                            {user.hasNewMessages && <span className={styles.unreadDot}></span>}
                        </div>

                        <div className={styles.chatInfo}>
                            <strong>{user.name}</strong>
                            <span className={styles.roleText}>
                                {getRoleLabel(user.role)}
                            </span>
                            <div className={`${styles.status} ${styles[getStatusClass(user.status)]}`}>
                                {user.status}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

// Utility: динамическое назначение класса по статусу
const getStatusClass = (status) => {
    switch (status) {
        case 'Ожидает оплаты':
            return 'waiting';
        case 'У курьера':
            return 'shipping';
        case 'Сделка удалась':
            return 'success';
        default:
            return 'default';
    }
};

export default ChatSidebar;