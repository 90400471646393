import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";

const COLORS = ["#4a90e2", "#00C49F", "#FFBB28", "#FF8042", "#845EC2"];

const DiscountChart = ({ purchaseHistory }) => {
    // Группируем суммы по скидкам
    const dataMap = {};

    for (const p of purchaseHistory) {
        const key = `${p.discount}%`;
        const earned = Math.round((p.amount * p.discount) / 100);
        dataMap[key] = (dataMap[key] || 0) + earned;
    }

    const data = Object.entries(dataMap).map(([name, value]) => ({ name, value }));

    if (data.length === 0) return null;

    return (
        <div style={{ width: "100%", maxWidth: "500px", margin: "40px auto" }}>
            <h3 style={{ textAlign: "center", color: "#111349" }}>Суммарный доход по <br/> скидкам</h3>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DiscountChart;